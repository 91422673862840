<template>
  <div class="module module-chart chart-half chart-sun" :data-status="ratioStatus">
    <LoadingScreen :loading="loading" />
    <ErrorWrapper :isOpen="errorboxOpen" :msg="errorboxMsg"/>
    <div class="chart-inner">
      <h2>{{ headingText }}</h2>

      <div class="chart-canvas">
        <div class="doughnut-center">
          <div class="days text">
            1.
            <template v-if="daysElapsed > 1">- {{ daysElapsed }}.</template>
          </div>
          <div class="month text">{{ monthText }}</div>
          <div class="now">
            <span>{{ solarPercentage|formatPercentage }}</span>
          </div>
        </div>

        <DoughnutChart
          chartId="chart-consumption-sun"
          cssClasses="chart-canvas"
          :chartData="chartData"
          :options="chartOptions"
          :width="1"
          :height="1"
        ></DoughnutChart>
      </div>

      <p class="foot text">
        <template v-if="ratioStatus === 'low'"> {{ lowText }} </template>
        <template v-else-if="ratioStatus === 'middle'"> {{ mediumText }} </template>
        <template v-else> <template > <p v-html="highText"></p> </template> </template>
      </p>
    </div>
  </div>
</template>

<script>
import DoughnutChart from "@/components/DoughnutChart";
import ErrorWrapper from '@/components/Helper/ErrorWrapper'
import LoadingScreen from '@/components/Helper/LoadingScreen'

import contractUpdater from "@/mixins/contractUpdater";
import refreshTimer from "@/mixins/refreshTimer";
import theme from "@/assets/settings/theme.js";
import portal_setting from "@/assets/settings/data.js";
// import { donutData } from "./DonutDummyData.ts";

export default {
  name: "ChartConsumptionSun",
  components: {
    DoughnutChart,
    ErrorWrapper,
    LoadingScreen,
  },
  mixins: [contractUpdater, refreshTimer],
  data: () => ({
    apiResponse: null,
    chartOptions: null,
    loading: true,
    errorboxOpen: false,
    errorboxMsg: '',
    low: "",
    medium: "",
    high: "",
    headingText: "",
    rightDonutData: async () => await import(`@/assets/overview/${portal_setting.right_donut_file}`),
  }),
  props: {
    heading: {
      type: String,
      required: false,
      default: "Solarquote"
    }
  },
  computed: {
    lowText() {
      return this.low.replace("solarPercentage", this.$options.filters.formatPercentage(this.solarPercentage))
    },
    mediumText() {
      return this.medium.replace("solarPercentage", this.$options.filters.formatPercentage(this.solarPercentage))
    },
    highText() {
      return this.high.replace("solarPercentage", this.$options.filters.formatPercentage(this.solarPercentage))
    },
    chartData() {
      if (!this.apiResponse) return {};

      var data = [];
      data.push(this.apiResponse.solar_ratio);
      data.push(1 - this.apiResponse.solar_ratio);

      var backgroundColors = [];
      if (this.apiResponse.solar_ratio_status === "high") {
        backgroundColors.push(this.$parent.getColor("status-ok"));
      } else if (this.apiResponse.solar_ratio_status === "middle") {
        backgroundColors.push(this.$parent.getColor("status-middle"));
      } else {
        backgroundColors.push(this.$parent.getColor("status-warn"));
      }
      backgroundColors.push(this.$parent.getColor("grey-light"));

      return {
        datasets: [
          {
            data: data,
            backgroundColor: backgroundColors,
            borderColor: theme.doughnut_border_color
          }
        ]
      };
    },
    daysElapsed() {
      if (!this.apiResponse) return 0;

      return this.apiResponse.days_elapsed;
    },
    monthText() {
      if (!this.apiResponse) return "Keine Daten empfangen.";

      return this.$moment(this.apiResponse.month, "M").format("MMMM");
    },
    ratioStatus() {
      if (!this.apiResponse) return "low";

      return this.apiResponse.solar_ratio_status;
    },
    solarPercentage() {
      if (!this.apiResponse) return 0;

      return this.apiResponse.solar_ratio;
    }
  },
  async created() {
    const {default: rightDonutData} = await this.rightDonutData()
    this.headingText = rightDonutData.heading;
    this.low = rightDonutData.text.low;
    this.medium = rightDonutData.text.medium;
    this.high = rightDonutData.text.high;

    this.chartOptions = {
      cutoutPercentage: 75,
      responsive: true,
      legend: false,
      animation: {
        animateScale: true,
        animateRotate: true,
        onProgress: () => {
          this.setChartHalfHeight();
        }
      },
      tooltips: {
        enabled: false
      },
      hover: {
        mode: null
      }
    };
  },
  filters: {
    formatPercentage(value) {
      if (typeof value === "number") {
        return (
          (value * 100).toLocaleString("de", {
            maximumFractionDigits: 0
          }) + " %"
        );
      }
      return "Keine Daten empfangen.";
    }
  },
  methods: {
    getData(timestamp = null) {
      if (this.selectedContract) {
        var path =
          this.$store.state.api.pathViewsV3 +
          "Contracts/" +
          this.selectedContract.hid +
          "/Measurements/Overview";

        if (this.$store.state.account.user.customers.length) {
          path +=
            "?customerId=" +
            this.$store.state.account.user.customers[
              this.$store.state.account.user.customers.length - 1
            ].customerInfo.id;
        }

        this.$http
          .get(path, {timeout: 30000})
          .then(response => {
            this.loading = false;
            this.apiResponse = response.data;
            // To show dummy data -->
            // this.apiResponse = donutData;
          })
          .catch(e => {
            this.errorboxMsg = e.message.startsWith('timeout')
              ? 'Die Datenabfrage dauert derzeit ungewöhnlich lang.'
              : undefined
            this.loading = false
            this.errorboxOpen = true
            console.error("%o", e);
          });
      }
    },
    setChartHalfHeight() {
      var $chartHalf = $(".chart-half .chart-inner");
      $chartHalf.css("min-height", "0");

      var chartMinHeight = 0;

      $chartHalf.each(function() {
        var chartHeight = $(this).outerHeight();

        if (chartHeight > chartMinHeight) {
          chartMinHeight = chartHeight;
        }
      });

      $chartHalf.css("min-height", chartMinHeight);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshData();
    });
  }
};
</script>

<style lang="scss" scoped>
@import "~@coreui/coreui/scss/functions";
@import "~@coreui/coreui/scss/variables";
@import "~@coreui/coreui/scss/mixins/_breakpoints";
@import "~@/assets/scss/custom";
@import "~@/assets/scss/components/consumption_doughnut";
</style>
