<template>
  <main v-if="selectedContract" class="c-main">
    <div class="container">
      <div class="row">
        <Co2Calculator
          :apiRefreshSeconds="3600"
          :timerInterval="300"
        ></Co2Calculator>
      </div>
    </div>
  </main>
  <main v-else class="c-main">
    <div class="container">
      <div class="row">
        <h2>
          Zugang besitzt derzeit keinen Vertrag, daher kann nichts angezeigt
          werden.
        </h2>
      </div>
    </div>
  </main>
</template>

<script>
import Co2Calculator from "@/components/Cockpit/Co2Calculator";

import contractUpdater from "@/mixins/contractUpdater";

export default {
  name: "Cockpit",
  components: {
    Co2Calculator,
  },
  mixins: [contractUpdater],
  methods: {},
  mounted() {
    this.$nextTick(() => {
      if (!this.$store.state.account.credentials) {
        this.$router.replace({
          name: "login",
        });
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/custom";

.row {
  margin: 0;
}

.container {
  @media (max-width: 991.98px) {
    max-width: 100%;
    padding: 0;
  }
}
</style>
