import { AxiosResponse } from 'axios'

import { ActionTree } from 'vuex'
import { AccountState } from './types'
import { RootState } from '../types'

export const actions: ActionTree<AccountState, RootState> = {
  getCustomerContracts(context, vue): any {
    return new Promise((resolve, reject) => {
      if (
        context.state.user.customers &&
        context.state.user.customers.length
      ) {
        let currentCustomer = context.state.user.customers[
          context.state.user.customers.length - 1
        ]
        vue.$http.get(
          `${context.rootState.api.pathViewsV3}Contracts`, {
          params: {
            customerId: currentCustomer.customerInfo.id
          }
        }
        ).then((response: AxiosResponse<any>) => {
          context.commit('setContracts', response.data.results)
          resolve(response.data)
        }).catch(() => {
          context.commit('popCustomer')
          reject()
        })
      }
    })
  }
}
