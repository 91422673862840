<template>
  <main v-if="selectedContract" class="c-main">
    <div class="container">
      <h2>Ihr Rechnungsverlauf</h2>
      <div v-if="invoices.length !== 0" class="module slide-invoices">
        <div id="invoice-root">
          <template v-for="(invoicesArray, contractNumber) in invoices">
            <div :key="'invoices-' + contractNumber">
              <h4
                @click="toogleContractNumber(contractNumber)"
                class="invoiceTitle"
              >
                <font-awesome-icon
                  class="icon"
                  :icon="selectedContractNumber === contractNumber ? 'caret-down' : 'caret-right'
                  "
                />
                Vertragsnummer {{ contractNumber }}
              </h4>
            </div>
            <div
              class="invoicesContent"
              v-if="selectedContractNumber === contractNumber"
              :key="contractNumber"
            >
              <table>
                <tr
                  v-for="invoiceObject in invoicesArray"
                  :key="'invoices-' + invoiceObject[0]"
                >
                  <td class="invoice-year">
                    <table class="invoice-table-inner">
                      <thead @click="toggleContractYear(invoiceObject[0])">
                        <tr>
                          <td>
                            <font-awesome-icon
                              class="icon"
                              :icon="selectedContractYear === invoiceObject[0] ? 'minus' : 'plus'"
                            />
                          </td>

                          <td>
                            <b>Rechnungen {{ invoiceObject[0] }}</b>
                          </td>

                          <td>
                            <b>{{ totalSum(invoiceObject[1]) }} €</b>
                          </td>

                          <td>
                            <b>{{ totalConsumption(invoiceObject[1]) }} kWh</b>
                          </td>

                          <td style="width: 64px;"></td>
                        </tr>
                      </thead>
                      <transition-group
                        tag="tbody"
                        name="slideFade"
                      >
                        <template v-if="selectedContractYear === invoiceObject[0]">
                          <tr v-for="nvc in invoiceObject[1]"
                            class="invoice-item"
                            :key="'invoices-' + contractNumber + '-' + nvc.hid"
                          >
                            <td></td>

                            <td v-if="nvc.contract.contract_type == '100.watt'">{{nvc.period_start | moment("MMMM")}}</td>
                            <td v-else-if="selectedContractYear === invoiceObject[0]">{{ nvc.period_start | moment("DD. MMMM") }} - {{ nvc.period_end | moment("DD. MMMM") }}</td>

                            <template v-if="nvc.contract.contract_type == 'TenantElectricity'">
                              <td>{{ nvc.total_sum.toFixed(2).toString().replace(".", ",") }} €</td>
                              <td>{{ nvc.consumption ? nvc.consumption.toFixed(2).toString().replace(".", ",") : '0.00' }} kWh</td>
                            </template>

                            <td @click="getInvoice(nvc)">
                              <font-awesome-icon
                                class="icon"
                                icon="cloud-download-alt"
                              ></font-awesome-icon>
                            </td>
                          </tr>
                        </template>
                      </transition-group>
                    </table>
                  </td>
                </tr>
              </table>
            </div>
          </template>
        </div>
      </div>
      <div v-else class="module slide-invoices">
        <div class="card">
          <div class="card-body invoice-text invoice-title">
            <h3>Bisher keine Rechnungen vorhanden</h3>
          </div>
        </div>
      </div>
    </div>
  </main>
  <main v-else class="c-main">
    <div class="container">
      <div class="row">
        <h2>
          Zugang besitzt derzeit keinen Vertrag, daher kann nichts angezeigt
          werden.
        </h2>
      </div>
    </div>
  </main>
</template>

<script>
import { saveAs } from "file-saver";
import contractUpdater from "@/mixins/contractUpdater";
import moment from "moment";

export default {
  name: "Invoices",
  data: () => ({
    apiResponse: null,
    selectedContractNumber: "",
    selectedContractYear: moment().startOf("day").format("Y"),
    isOpen: false,
  }),
  mixins: [contractUpdater],
  computed: {
    invoices() {
      if (!this.apiResponse) return {};

      let invoicesMap = {};
      let contractNumber;

      this.apiResponse.filter(invoice=> invoice.status==="approved")
        .forEach((invoice) => {
        let year = invoice.creation_date?.substring(0, 4);
        const contract = this.$store.state.account.user.contracts.find(storeContract => storeContract.id === invoice.contract_id)
        invoice["contract"] = contract
        contractNumber = invoice.contract.contract_number;

        if (invoicesMap[contractNumber] === undefined)
          invoicesMap[contractNumber] = new Map();
        if (invoicesMap[contractNumber].get(year) === undefined)
          invoicesMap[contractNumber].set(year, []);

        invoicesMap[contractNumber].get(year).push(invoice);
      });

      if (Object.keys(invoicesMap).length == 1)
        this.selectedContractNumber = contractNumber;
      return invoicesMap;
    },
  },
  methods: {
    getInvoice(invoiceObject) {
      var path =
        this.$store.state.api.pathViewsV3 +
        "Contracts/" +
        this.selectedContract.hid +
        "/Invoices/" +
        invoiceObject.hid;

      this.$http
        .get(path, {
          responseType: "blob",
        })
        .then((response) => {
          const invoicePdf = new Blob([response.data], {
            type: "application/pdf",
          });

          if (response.headers["content-type"] === "application/pdf") {
            saveAs(
              invoicePdf,
              response.headers["content-disposition"].slice(
                response.headers["content-disposition"].indexOf("'") + 1,
                response.headers["content-disposition"].lastIndexOf("'")
              )
            );
          } else {
            // TODO: Create error message
            console.error(response.data);
          }
        })
        .catch((e) => {
          console.error(this.name + ":", e);
        });
    },
    refreshData() {
      if (this.selectedContract.hid) {
        var path =
          this.$store.state.api.pathViewsV3 +
          "Contracts/" +
          this.selectedContract.hid +
          "/Invoices";

        if (this.$store.state.account.user.customers.length) {
          path +=
            "?customerId=" +
            this.$store.state.account.user.customers[
              this.$store.state.account.user.customers.length - 1
            ].customerInfo.id;
        }
        this.$http(path).then(res => {
          const pathWithLimit = path + (path.includes("?")?"&":"?") + "offset=0&limit=" + res.data.count;
          this.$http
          .get(pathWithLimit)
          .then((response) => {
            this.apiResponse = response.data.results;
          })
          .catch((e) => {
            console.error("%o", e);
          });
        });
      }
    },
    toogleContractNumber(contractNumber) {
      if (contractNumber === this.selectedContractNumber) {
        this.selectedContractNumber = "";
        this.selectedContractYear = moment().startOf("day").format("Y");
        return;
      }
      this.selectedContractNumber = contractNumber;
      this.selectedContractYear = moment().startOf("day").format("Y");
    },
    toggleContractYear(contractYear) {
      if (contractYear === this.selectedContractYear) {
        this.selectedContractYear = "";
        return;
      }
      this.selectedContractYear = contractYear;
    },
    toggle: function () {
      this.isOpen = !this.isOpen;
    },
    totalSum(nvcData) {
      let total = 0;
      nvcData.forEach((elem) => {
        total += elem.total_sum;
      });
      return total.toFixed(2).toString().replace(".", ",");
    },
    totalConsumption(nvcData) {
      let total = 0;
      nvcData.forEach((elem) => {
        total += elem.consumption ? elem.consumption : 0;
      });
      return total.toFixed(2).toString().replace(".", ",");
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.$store.state.account.credentials) {
        this.$router.replace({
          name: "login",
        });
      } else {
        this.refreshData();
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/custom";

.slideFade-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.15s;
}
.slideFade-leave-active {
  transition: all 0.15s ease-out;
}
.slideFade-enter,
.slideFade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.container > h2 {
  text-align: left;
  color: $invoiceHeading;
}

.slide-invoices {
  background-color: $invoiceBackground;
  backdrop-filter: $contentBoxBlur;
  color: $invoiceAccent;

  #invoice-root {
    & > div > .invoiceTitle {
      display: flex;
      justify-content: center;
      padding: 10px;
      margin: 5px;
      color: $invoicePrimary;
      transition: all 0.2s ease-in-out;

      @media (max-width: 991.98px) {
        &:active {
          color: $invoiceAccent;
        }
      }

      @media (min-width: 991.98px) {
        &:hover {
          cursor: pointer;
          color: $invoiceAccent;
        }
      }

      & > svg {
        margin: auto 5px;
        transition: all 0.2s ease-in-out;
      }
    }

    .invoicesContent {
      text-align: left;
      padding: 15px;

      table{
        width: 100%;

        &.invoice-table-inner{
          &>thead{
            display: flex;
          }

          &>tbody{
            display: flex;
            flex-direction: column;

            &>tr.invoice-item{
              display: flex;
              flex-flow: row wrap;

              @media (max-width: 767px) {
                & > td{
                  flex: 1;
                  &:first-child(){
                    flex: initial;
                  }
                  &:nth-child(2){
                    flex: 100%;
                  }
                  &:nth-child(4){
                    text-align: center;
                  }
                  &:last-child(){
                    text-align: right;
                  }
                }
              }

              &>td:last-child(){
                margin-left: auto;
              }
            }
          }
        }

        thead{
          &:hover{
            cursor: pointer;
            color: $invoicePrimary;
            transition: .2s all ease-in-out;
          }
        }

        tr.invoice-item{
          border-spacing: 5px;
          transition: .2s all ease-in-out;

          &:hover{
            background-color: $invoiceBlock;
            color: $invoicePrimary;
          }
        }

        td{
          padding-top: 7px;
          padding-bottom: 7px;
          padding-right: 7px;

          &:first-child{
            width: 34px;
            padding-left: 7px;
            padding-right: 0;
          }

          &:nth-child(2),
          &:nth-child(3){
            width: 350px;

            @media (max-width: 767px) {
              width: initial;
            }
          }

          .icon{
            &:hover{
              cursor: pointer;
              transition: .2s all ease-in-out;
            }
          }
        }
      }
    }
  }
}

// Animation
.slideFade-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.15s;
}
.slideFade-leave-active {
  transition: all 0.15s ease-out;
}
.slideFade-enter,
.slideFade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>
