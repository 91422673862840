module.exports = {
    accent_color: process.env.VUE_APP_ACCENT_COLOR,
    background_color: process.env.VUE_APP_BACKGROUND_COLOR || null,
    background_pic: process.env.VUE_APP_BACKGROUND_PIC || null,
    background_gradient_color_1: process.env.VUE_APP_BKG_GRADIENT_COLOR_1 || null,
    background_gradient_color_2: process.env.VUE_APP_BKG_GRADIENT_COLOR_2 || null,
    background_gradient_settings: process.env.VUE_APP_BKG_GRADIENT_SETTINGS || null,
    chart_box_background_color: process.env.VUE_APP_CHART_BOX_BACKGROUND_COLOR,
    doughnut_border_color: process.env.VUE_APP_DOUGHNUT_BORDER_COLOR,
    donut_text_color: process.env.VUE_APP_DONUT_TEXT_COLOR,
    box_background_color: process.env.VUE_APP_BOX_BACKGROUND_COLOR,
    login_box_background_color: process.env.VUE_APP_LOGIN_BOX_BACKGROUND_COLOR,
    login_primary_color: process.env.VUE_APP_LOGIN_PRIMARY_COLOR,
    login_accent_color: process.env.VUE_APP_LOGIN_ACCENT_COLOR,
    login_button_color: process.env.VUE_APP_LOGIN_BUTTON_COLOR,
    faq_background_color: process.env.VUE_APP_FAQ_BACKGROUND_COLOR,
    faq_heading_color: process.env.VUE_APP_FAQ_HEADING_COLOR,
    faq_question_color: process.env.VUE_APP_FAQ_QUESTION_COLOR,
    faq_answer_color: process.env.VUE_APP_FAQ_ANSWER_COLOR,
    invoice_background_color: process.env.VUE_APP_INVOICE_BACKGROUND_COLOR,
    invoice_heading_color: process.env.VUE_APP_INVOICE_HEADING_COLOR,
    invoice_primary_color: process.env.VUE_APP_INVOICE_PRIMARY_COLOR,
    invoice_accent_color: process.env.VUE_APP_INVOICE_ACCENT_COLOR,
    invoice_block_color: process.env.VUE_APP_INVOICE_BLOCK_COLOR,
    favicon: process.env.VUE_APP_FAVICON,
    font_color_primary: process.env.VUE_APP_FONT_COLOR_PRIMARY,
    font_color_secondary: process.env.VUE_APP_FONT_COLOR_SECONDARY,
    color_chart_title: process.env.VUE_APP_COLOR_CHART_TITLE_COLOR,
    color_status_ok: process.env.VUE_APP_COLOR_STATUS_OK,
    color_status_middle: process.env.VUE_APP_COLOR_STATUS_MIDDLE,
    color_status_warn: process.env.VUE_APP_COLOR_STATUS_WARN,
    color_chart_solar: process.env.VUE_APP_COLOR_CHART_SOLAR,
    color_chart_consumption: process.env.VUE_APP_COLOR_CHART_CONSUMPTION,
    color_chart_average: process.env.VUE_APP_COLOR_CHART_AVERAGE,
    color_chart_load: process.env.VUE_APP_COLOR_CHART_LOAD,
    color_chart_arrow: process.env.VUE_APP_COLOR_CHART_ARROW,
    color_chart_font: process.env.VUE_APP_COLOR_CHART_FONT_COLOR,
    color_chart_grid: process.env.VUE_APP_COLOR_CHART_GRID,
    color_co2svg: process.env.VUE_APP_COLOR_CO2SVG,
    co2_primary: process.env.VUE_APP_CO2_PRIMARY_COLOR,
    co2_box_background: process.env.VUE_APP_CO2_BOX_BACKGROUND,
    logo: process.env.VUE_APP_LOGO,
    menu_logo: process.env.VUE_APP_MENU_LOGO,
    menu_background_color: process.env.VUE_APP_MENU_BACKGROUND_COLOR,
    menu_user_color: process.env.VUE_APP_MENU_USER_COLOR,
    menu_icon_prefix: process.env.VUE_APP_MENU_ICON_PREFIX || "einhundert",
    menu_icon_text_primary: process.env.VUE_APP_MENU_ICON_TEXT_PRIMARY_COLOR,
    menu_icon_text_accent: process.env.VUE_APP_MENU_ICON_TEXT_ACCENT_COLOR,
    primary_color: process.env.VUE_APP_PRIMARY_COLOR,
    app_font: process.env.VUE_APP_FONT || "Futura LT W01 Book",
    app_font_bold: process.env.VUE_APP_FONT_BOLD || "Futura LT W01 Bold",
};
