const data = {
    faq_url: process.env.VUE_APP_FAQ_URL,
    faq_file: process.env.VUE_APP_FAQ_FILE || "questions.json",
    impress_url: process.env.VUE_APP_IMPRESS_URL,
    site_name: process.env.VUE_APP_SITE_NAME,
    site_url: process.env.VUE_APP_SITE_URL,
    sub_domain: process.env.VUE_APP_SUB_DOMAIN,
    login_message: process.env.VUE_APP_LOGIN_MESSAGE,
    font_name: process.env.VUE_APP_FONT,
    left_donut_file: process.env.VUE_APP_LEFT_DONUT_FILE || "leftDonut.json",
    right_donut_file: process.env.VUE_APP_RIGHT_DONUT_FILE || "rightDonut.json",
    histogram_file: process.env.VUE_APP_HISTOGRAM_FILE || "histogram.json",
    co2_file: process.env.VUE_APP_CO2_DATA_FILE || "data.json",
};
export default data;
