import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import moment from 'moment'

import store from '@/store/index'

let authTokenRequest: any
let refreshTokenRequest: any

function resetAuthTokenRequest () {
  authTokenRequest = null
}

function resetRefreshTokenRequest () {
  refreshTokenRequest = null
}

function getAuthTokenRequest () {
  if (!authTokenRequest) {
    let queryTmp = `grant_type=password&client_id=${encodeURIComponent(store.state.api.clientId)}&username=${encodeURIComponent(store.state.account.user.username)}&password=${encodeURIComponent(store.state.account.user.password)}`
    if (store.state.api.clientSecret) {
      queryTmp += `&client_secret=${encodeURIComponent(store.state.api.clientSecret)}`
    }
    authTokenRequest = httpBase.post(
      'oauth/token', queryTmp
    )
    authTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest)
  }
  return authTokenRequest
}

function getRefreshAuthTokenRequest () {
  if (!refreshTokenRequest) {
    let queryTmp = `grant_type=refresh_token&client_id=${store.state.api.clientId}&refresh_token=${store.state.account.credentials!.refresh_token}`
    if (store.state.api.clientSecret) {
      queryTmp += `&client_secret=${encodeURIComponent(store.state.api.clientSecret)}`
    }
    refreshTokenRequest = httpBase.post(
      'oauth/token', queryTmp
    )
    refreshTokenRequest.then(resetRefreshTokenRequest, resetRefreshTokenRequest)
  }
  return refreshTokenRequest
}

export async function tryGetAuthToken (originalRequest: AxiosRequestConfig): Promise<any> {
  let authRequest

  if (store.state.account.credentials && store.state.account.credentials.refresh_token) {
    authRequest = getRefreshAuthTokenRequest()
  } else {
    authRequest = getAuthTokenRequest()
  }

  return authRequest.then(
    (authResponse: AxiosRequestConfig) => {
      store.commit('account/setCredentials', authResponse.data)
      if (originalRequest) {
        originalRequest.headers.Authorization = `Bearer ${authResponse.data.access_token}`
        return Promise.resolve(originalRequest)
      }
      return Promise.resolve(authResponse)
    },
    (error: AxiosError) => {
      if (error.response && error.response.status === 400 && error.response.data.error === 'invalid_grant') {
        return getAuthTokenRequest().then(
          (authResponse: AxiosRequestConfig) => {
            store.commit('account/setCredentials', authResponse.data)
            if (originalRequest) {
              originalRequest.headers.Authorization = `Bearer ${authResponse.data.access_token}`
              return Promise.resolve(originalRequest)
            }
            return Promise.resolve(authResponse)
          }
        )
      }
      return Promise.reject(error)
    }
  )
}

export const httpBase = axios.create({
  baseURL: store.state.api.path
})

httpBase.interceptors.request.use((request: AxiosRequestConfig) => {
  if (store.state.account.credentials) {
    if (moment(store.state.account.credentials.expiresInDate) < moment() && request.url !== 'oauth/token') {
      return tryGetAuthToken(request)
    } else {
      request.headers.Authorization = `Bearer ${store.state.account.credentials.access_token}`
    }
  }
  return request
})

httpBase.interceptors.response.use(undefined, (error: any) => {
  if (error.response && error.response.status === 401) {
    if (error.request.url !== 'oauth/token') {
      return tryGetAuthToken(error.config).then(
        (originalRequest: AxiosRequestConfig) => {
          return httpBase(originalRequest)
        }
      )
    }
    store.commit('account/logoutUser')
  }

  return Promise.reject(error)
})
