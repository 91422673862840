<template>
  <main class="c-main">
    <div class="container">
      <div class="module module-logo">
        <div class="inner"></div>
      </div>

      <div class="module module-form">
        <h1>Neues Passwort</h1>

        <p>Bitte tragen Sie Ihr neues, gewünschtes Passwort ein und bestätigen Sie dieses im zweiten Feld.</p>

        <p>
          <router-link :to="{ name: 'login' }">Zurück zum Login</router-link>
        </p>

        <div v-if="errorMessage" class="alert alert-danger" role="alert">{{ errorMessage }}</div>

        <div
          v-if="success"
          class="alert alert-info"
          role="alert"
        >Ihr Passwort wurde erfolgreich geändert. Sie können sich nun einloggen.</div>

        <form @submit.prevent="checkResetForm">
          <input type="hidden" v-model="uid" value />
          <input type="hidden" v-model="token" value />

          <div class="form-group">
            <input
              type="password"
              v-model="new_password1"
              placeholder="Neues Passwort"
              value
              class="form-control"
              required
            />
          </div>

          <div class="form-group">
            <input
              type="password"
              v-model="new_password2"
              placeholder="Neues Passwort wiederholen"
              value
              class="form-control"
              required
            />
          </div>

          <div class="form-group">
            <input
              v-if="!new_password1.length || !new_password2.length"
              type="submit"
              name="submit"
              value="Passwort zurücksetzen"
              class="btn btn-primary btn-block"
              disabled
            />
            <input
              v-else
              type="submit"
              name="submit"
              value="Passwort zurücksetzen"
              class="btn btn-primary btn-block"
            />
          </div>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "password_reset",
  data: () => ({
    errorMessage: "",
    new_password1: "",
    new_password2: "",
    token: "",
    uid: "",
    success: false
  }),
  methods: {
    checkResetForm(e) {
      if (!this.$route.query.uid || !this.$route.query.token) {
        this.$router.push({
          name: "password_forget"
        });
      }
      this.token = this.$route.query.token;
      this.uid = this.$route.query.uid;

      if (
        this.new_password1.length <= 8 ||
        this.new_password1.match(/^[0-9]+$/)
      ) {
        this.errorMessage =
          "Bitte stellen Sie sicher, dass das neue Passwort mindestens 9 Zeichen lang ist und nicht nur aus Zahlen besteht.";
        return;
      }

      if (this.new_password1 !== this.new_password2) {
        this.errorMessage =
          "Bitte stellen Sie sicher, dass beide Passwörter identisch sind.";
        return;
      }

      this.$http
        .post(
          this.$store.state.api.pathViews + "PasswordResetConfirm",
          `uid=${this.uid}&token=${
            this.token
          }&new_password1=${encodeURIComponent(
            this.new_password1
          )}&new_password2=${encodeURIComponent(this.new_password2)}`
        )
        .then(response => {
          if (this.errorMessage) this.errorMessage = "";
          this.success = true;
        })
        .catch(error => {
          this.success = false;
          if (error.response && error.response.status === 400) {
            this.errorMessage =
              "Es gab ein Problem beim Verarbeiten des E-Mail Links. Bitte fordern Sie die Passwort-Vergessen E-Mail erneut an.";
            return;
          }
          this.errorMessage =
            "Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es später erneut.";
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/custom";

.container {
  max-width: 400px;
  margin: 0 auto;
}

.module-form {
  background: $another-60;
  padding: 70px 20px 5px;

  text-align: center;
  color: $accent;

  h1 {
    text-transform: uppercase;
    font-family: $font-bold;
    font-size: 32px;

    padding: 10px 0;
    color: $accent;
  }

  .alert {
    &.alert-danger,
    &.alert-info {
      border: none;
      color: $accent;

      a {
        color: $accent;
        text-decoration: underline;
      }
    }

    &.alert-danger {
      background: $red;
    }

    &.alert-info {
      background: $blue;
    }
  }

  input {
    border-color: $grey-light;
  }

  a {
    color: $primary;
    &:hover {
      color: $accent;
    }
  }

  .btn-primary {
    text-transform: uppercase;
    background: $primary;
    color: $accent;
    border: none;

    padding-top: 8px;
    padding-bottom: 8px;

    cursor: pointer;
    border-radius: 0;
  }
}

.module-login {
  max-width: 400px;
  margin: 0 auto;
}

.module-logo {
  $size: 100px;

  width: $size;
  height: $size;

  margin: 0 auto;

  position: relative;
  top: 60px;

  .inner {
    @if $logo {
      background-image: url("~@/assets/img/logos/"+$logo);
      background-size: contain;
    }

    width: 100%;
    height: 100%;
  }
}
</style>
