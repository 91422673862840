<template>
  <div class="xmas-container" v-if="!isClosed">
    <button class="xmas-close" v-on:click="isClosed = true">
      <img src="@/assets/icons/close-24px.svg" />
    </button>
    <div class="row xmas-banner">
      <div class="col-sm-2">Fröhliche Weihnachten und Danke!</div>
      <div class="col-sm-4">
        <p>
          Sie haben massgeblich dazu beigetragen, dass wir {{ currentYear }} mit
          den in ganz Deutschland verteilten 100.solarhäusern mehr als 142
          Tonnen CO<sub>2</sub> einsparen konnten.
        </p>
        <p class="small">
          Darauf können wir alle gemeinsam mächtig stolz sein.<br />
          Vielen Dank daher auch an Sie ganz persönlich!<br />
          Danke auch für Ihre Entscheidung für die Energiewende auf dem eigenen
          Dach.
        </p>
        <p>Das Team von EINHUNDERT Energie GmbH</p>
        <div class="col-sm-2">
          <img src="@/assets/img/100xmas-lights.png" draggable="false" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EINHUNDERTxmas",
  data: () => ({
    isClosed: false,
    bannerColor: "",
  }),
  methods: {
    getBgColor() {
      var bgColors = ["red", "green"];
      this.bannerColor = bgColors[Math.floor(Math.random() * bgColors.length)];
    },
  },
  beforeMount() {
    this.getBgColor();
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/custom";
.xmas-container {
  transition: 0.2s all ease-in-out;
  width: 95%;
  margin: 10px auto 0px;
  position: relative;
  > .xmas-close {
    position: absolute;
    color: red;
    cursor: pointer;
    top: 6px;
    right: 0px;
    background: none;
    border: none;
    &:focus {
      outline: none;
    }
    > img {
      background-color: rgba(0, 0, 0, 0.25);
      border-radius: 25px;
      padding: 3px;
    }
  }
  > img {
    width: 100%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
  }
}
</style>
