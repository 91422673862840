import Vue from 'vue'
import Vuex from 'vuex'

import { account } from './account'
import { api } from './api'

import { accountInitialState } from '@/store/account'
import { apiInitialState } from '@/store/api'

import { version } from '../../package.json'

Vue.use(Vuex)

const store = new Vuex.Store<any>({
  modules: {
      account,
      api
  },
  state: {
    version: '',
    account: accountInitialState,
    api: apiInitialState
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem('100.store')) {
        let store = JSON.parse(localStorage.getItem('100.store') || JSON.parse(state))
        if (store.version === version) {
          this.replaceState(Object.assign(state, store))
        } else {
          state.version = version
        }
      }
    }
  },
  getters: {}
})
store.subscribe((mutation, state) => {
  localStorage.setItem('100.store', JSON.stringify(state))
})

export default store
