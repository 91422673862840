<template>
  <div id="widget-co2-calc">
    <LoadingScreen :loading="loading" />
    <ErrorWrapper :isOpen="errorboxOpen" :msg="errorboxMsg" />
    <div class="co2-savings">
      <h3 v-html="heading" class="text"></h3>
      <div class="image-container">
        <svg
          class="co2-cloud"
          version="1.1"
          id="Ebene_1"
          xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
          xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
          xmlns:svg="http://www.w3.org/2000/svg"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 100 100"
          style="enable-background:new 0 0 100 100;"
          xml:space="preserve"
        >
          <g transform="translate(0,-952.36218)">
            <g transform="translate(6.999762,959.33092)">
              <path
                class="st0"
                d="M58.4,9c-6.5,0-13.5,3.7-19.2,11.8c-7-5-17.1-2-16.1,9c-2.5,0.6-4.7,1.5-6.6,2.8c2.7-0.3,5.5,0.2,8.2,1.7
                c5.3-6.9,11.8-10.6,18.1-11.2c2.2-0.2,4.5,0,6.6,0.6c8.3,2.2,14.8,9.9,16.7,20.9c8,3.3,12.5,9.6,12.9,16.2
                c16.3-5.4,16.1-24.9-0.7-30.9C76.4,16.8,67.9,9,58.4,9z M44.4,25c-6.5,0-13.5,3.7-19.2,11.8c-7-5-17.1-2-16.1,9
                c-17,4-20,24.1-1,31.1h56.2c17-5,17-25.1,0-31.1C62.4,32.8,53.9,25,44.4,25z"
              />
              <text x="36%" y="60%" font-size=".5em" fill="white" dominant-baseline="middle" text-anchor="middle">{{ co2Amount|formatFloat('kg', 0) }}</text>
            </g>
          </g>
        </svg>
      </div>
      <p class="text" v-html="co2SavedText"></p>
      <p class="note-text" v-html="note"></p>
    </div>
    <div class="co2-comparison">
      <div class="co2-comparision-trees">
        <p class="text" v-html="comparison"></p>

        <div class="image-container">
          <template v-for="n in Math.min(treeAmountObject.wholeTrees, 14)">
            <svg
              class="whole-tree"
              :style="thumbnailSize"
              :key="n"
              version="1.1"
              id="Ebene_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 333 333"
              style="enable-background:new 0 0 333 333;"
              xml:space="preserve"
            >
              <g>
                <path
                  class="st0"
                  d="M144,66c-17-12-45-7-54,13c-7-1-14,1-20,5c-3-2-6-3-9-6c-9,6-22,1-24-11c-1-9,6-18,15-18c4-12,15-21,28-21
                  c5-21,34-25,44-6c5-3,12-4,18-1c6-14,25-16,33-3c5-3,11-2,15,2c-4,4-7,10-8,17c-2,1-5,2-7,3C159,38,145,51,144,66z M114,194l18,17
                  c-3-6-4-11-6-17l4-3c7,15,10,28,23,37c0-10,0-9,1-19c-14-15-21-26-30-42c-9,2-17-3-21-10c-1,0-2,1-3,1L114,194z M223,176l-34,29
                  c-12,9-5,89,6,120h-56c6-23,10-60,13-82c-3-3-5-6-7-9l-38-37l-14-37c-6,0-13-3-17-9c-7,17-35,15-37-5c-17-3-17-27,0-29
                  c-3-15,13-30,29-21c5-10,17-13,27-8c5-29,51-29,56,0c18-14,33,1,31,21c-4,2-7,4-10,8c-29,3-38,37-10,53l2-6c-22-11-13-43,13-41
                  c2-6,7-8,12-9c-4-22,28-31,38-11c8-4,18,0,21,9c11-7,26,3,22,16c9-3,19,4,19,14c0,9-9,15-18,14c3,18-18,29-32,17
                  C235,175,229,176,223,176z M158,198l2-18l-15-12c-1,0-10-1-13-4C139,177,144,186,158,198z M170,206l18-30c-2-4-3-8-2-12
                  c-4,2-8,2-12,2C170,181,168,192,170,206z M194,182c-8,17-9,15,8,3C199,185,196,184,194,182z M280,105c-2,3-4,5-7,6c-5-6-14-10-22-8
                  c-5-6-13-9-21-8c-11-13-31-14-42-1c-4-14-20-22-33-16c-10-18,4-36,22-31c3-2,7-5,11-6c-1-12,11-23,24-20c10-23,44-12,39,12
                  c24-1,38,19,28,32c14-2,27,6,28,18C308,96,295,107,280,105z"
                />
              </g>
            </svg>
            <br v-if="false && treeAmountObject.wholeTrees <= 9 && n % 5 == 0" :key="n" />
            <br v-if="false && treeAmountObject.wholeTrees > 9 && n % 7 == 0" :key="n" />
          </template>

          <template v-if="treeAmountObject.partlyTree">
            <svg
              v-if="treeAmountObject.partlyTree <= 0.375"
              class="partly-tree"
              :style="thumbnailSize"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 333 333"
              style="enable-background:new 0 0 333 333;"
              xml:space="preserve"
            >
              <g id="Ebene_1">
                <g>
                  <path
                    class="st0"
                    d="M144,66c-17-12-45-7-54,13c-7-1-14,1-20,5c-3-2-6-3-9-6c-9,6-22,1-24-11c-1-9,6-18,15-18c4-12,15-21,28-21
                    c5-21,34-25,44-6c5-3,12-4,18-1c6-14,25-16,33-3c5-3,11-2,15,2c-4,4-7,10-8,17c-2,1-5,2-7,3C159,38,145,51,144,66z M114,194l18,17
                    c-3-6-4-11-6-17l4-3c7,15,10,28,23,37c0-10,0-9,1-19c-14-15-21-26-30-42c-9,2-17-3-21-10c-1,0-2,1-3,1L114,194z M223,176l-34,29
                    c-12,9-5,89,6,120h-56c6-23,10-60,13-82c-3-3-5-6-7-9l-38-37l-14-37c-6,0-13-3-17-9c-7,17-35,15-37-5c-17-3-17-27,0-29
                    c-3-15,13-30,29-21c5-10,17-13,27-8c5-29,51-29,56,0c18-14,33,1,31,21c-4,2-7,4-10,8c-29,3-38,37-10,53l2-6c-22-11-13-43,13-41
                    c2-6,7-8,12-9c-4-22,28-31,38-11c8-4,18,0,21,9c11-7,26,3,22,16c9-3,19,4,19,14c0,9-9,15-18,14c3,18-18,29-32,17
                    C235,175,229,176,223,176z M158,198l2-18l-15-12c-1,0-10-1-13-4C139,177,144,186,158,198z M170,206l18-30c-2-4-3-8-2-12
                    c-4,2-8,2-12,2C170,181,168,192,170,206z M194,182c-8,17-9,15,8,3C199,185,196,184,194,182z M280,105c-2,3-4,5-7,6
                    c-5-6-14-10-22-8c-5-6-13-9-21-8c-11-13-31-14-42-1c-4-14-20-22-33-16c-10-18,4-36,22-31c3-2,7-5,11-6c-1-12,11-23,24-20
                    c10-23,44-12,39,12c24-1,38,19,28,32c14-2,27,6,28,18C308,96,295,107,280,105z"
                  />
                </g>
              </g>
              <g id="Ebene_2">
                <g>
                  <defs>
                    <rect id="SVGID_1_" x="26.1" y="249" width="287.1" height="75.8" />
                  </defs>
                  <clipPath id="SVGID_2_">
                    <use xlink:href="#SVGID_1_" style="overflow:visible;" />
                  </clipPath>
                  <g class="st1">
                    <path
                      class="st2"
                      d="M143.8,65.8c-17-12-45-7-54,13c-7-1-14,1-20,5c-3-2-6-3-9-6c-9,6-22,1-24-11c-1-9,6-18,15-18
                      c4-12,15-21,28-21c5-21,34-25,44-6c5-3,12-4,18-1c6-14,25-16,33-3c5-3,11-2,15,2c-4,4-7,10-8,17c-2,1-5,2-7,3
                      C158.8,37.8,144.8,50.8,143.8,65.8z M113.8,193.8l18,17c-3-6-4-11-6-17l4-3c7,15,10,28,23,37c0-10,0-9,1-19c-14-15-21-26-30-42
                      c-9,2-17-3-21-10c-1,0-2,1-3,1L113.8,193.8z M222.8,175.8l-34,29c-12,9-5,89,6,120h-56c6-23,10-60,13-82c-3-3-5-6-7-9l-38-37
                      l-14-37c-6,0-13-3-17-9c-7,17-35,15-37-5c-17-3-17-27,0-29c-3-15,13-30,29-21c5-10,17-13,27-8c5-29,51-29,56,0c18-14,33,1,31,21
                      c-4,2-7,4-10,8c-29,3-38,37-10,53l2-6c-22-11-13-43,13-41c2-6,7-8,12-9c-4-22,28-31,38-11c8-4,18,0,21,9c11-7,26,3,22,16
                      c9-3,19,4,19,14c0,9-9,15-18,14c3,18-18,29-32,17C234.8,174.8,228.8,175.8,222.8,175.8z M157.8,197.8l2-18l-15-12c-1,0-10-1-13-4
                      C138.8,176.8,143.8,185.8,157.8,197.8z M169.8,205.8l18-30c-2-4-3-8-2-12c-4,2-8,2-12,2C169.8,180.8,167.8,191.8,169.8,205.8z
                      M193.8,181.8c-8,17-9,15,8,3C198.8,184.8,195.8,183.8,193.8,181.8z M279.8,104.8c-2,3-4,5-7,6c-5-6-14-10-22-8c-5-6-13-9-21-8
                      c-11-13-31-14-42-1c-4-14-20-22-33-16c-10-18,4-36,22-31c3-2,7-5,11-6c-1-12,11-23,24-20c10-23,44-12,39,12c24-1,38,19,28,32
                      c14-2,27,6,28,18C307.8,95.8,294.8,106.8,279.8,104.8z"
                    />
                  </g>
                </g>
              </g>
            </svg>

            <svg
              v-else-if="treeAmountObject.partlyTree <= 0.625"
              class="partly-tree"
              :style="thumbnailSize"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 333 333"
              style="enable-background:new 0 0 333 333;"
              xml:space="preserve"
            >
              <g id="Ebene_1">
                <g>
                  <path
                    class="st0"
                    d="M144,66c-17-12-45-7-54,13c-7-1-14,1-20,5c-3-2-6-3-9-6c-9,6-22,1-24-11c-1-9,6-18,15-18c4-12,15-21,28-21
                    c5-21,34-25,44-6c5-3,12-4,18-1c6-14,25-16,33-3c5-3,11-2,15,2c-4,4-7,10-8,17c-2,1-5,2-7,3C159,38,145,51,144,66z M114,194l18,17
                    c-3-6-4-11-6-17l4-3c7,15,10,28,23,37c0-10,0-9,1-19c-14-15-21-26-30-42c-9,2-17-3-21-10c-1,0-2,1-3,1L114,194z M223,176l-34,29
                    c-12,9-5,89,6,120h-56c6-23,10-60,13-82c-3-3-5-6-7-9l-38-37l-14-37c-6,0-13-3-17-9c-7,17-35,15-37-5c-17-3-17-27,0-29
                    c-3-15,13-30,29-21c5-10,17-13,27-8c5-29,51-29,56,0c18-14,33,1,31,21c-4,2-7,4-10,8c-29,3-38,37-10,53l2-6c-22-11-13-43,13-41
                    c2-6,7-8,12-9c-4-22,28-31,38-11c8-4,18,0,21,9c11-7,26,3,22,16c9-3,19,4,19,14c0,9-9,15-18,14c3,18-18,29-32,17
                    C235,175,229,176,223,176z M158,198l2-18l-15-12c-1,0-10-1-13-4C139,177,144,186,158,198z M170,206l18-30c-2-4-3-8-2-12
                    c-4,2-8,2-12,2C170,181,168,192,170,206z M194,182c-8,17-9,15,8,3C199,185,196,184,194,182z M280,105c-2,3-4,5-7,6
                    c-5-6-14-10-22-8c-5-6-13-9-21-8c-11-13-31-14-42-1c-4-14-20-22-33-16c-10-18,4-36,22-31c3-2,7-5,11-6c-1-12,11-23,24-20
                    c10-23,44-12,39,12c24-1,38,19,28,32c14-2,27,6,28,18C308,96,295,107,280,105z"
                  />
                </g>
              </g>
              <g id="Ebene_2">
                <g>
                  <defs>
                    <rect id="SVGID_1_" x="13" y="166.5" width="307.5" height="158.5" />
                  </defs>
                  <clipPath id="SVGID_2_">
                    <use xlink:href="#SVGID_1_" style="overflow:visible;" />
                  </clipPath>
                  <g class="st1">
                    <path
                      class="st2"
                      d="M143.8,65.8c-17-12-45-7-54,13c-7-1-14,1-20,5c-3-2-6-3-9-6c-9,6-22,1-24-11c-1-9,6-18,15-18
                      c4-12,15-21,28-21c5-21,34-25,44-6c5-3,12-4,18-1c6-14,25-16,33-3c5-3,11-2,15,2c-4,4-7,10-8,17c-2,1-5,2-7,3
                      C158.8,37.8,144.8,50.8,143.8,65.8z M113.8,193.8l18,17c-3-6-4-11-6-17l4-3c7,15,10,28,23,37c0-10,0-9,1-19c-14-15-21-26-30-42
                      c-9,2-17-3-21-10c-1,0-2,1-3,1L113.8,193.8z M222.8,175.8l-34,29c-12,9-5,89,6,120h-56c6-23,10-60,13-82c-3-3-5-6-7-9l-38-37
                      l-14-37c-6,0-13-3-17-9c-7,17-35,15-37-5c-17-3-17-27,0-29c-3-15,13-30,29-21c5-10,17-13,27-8c5-29,51-29,56,0c18-14,33,1,31,21
                      c-4,2-7,4-10,8c-29,3-38,37-10,53l2-6c-22-11-13-43,13-41c2-6,7-8,12-9c-4-22,28-31,38-11c8-4,18,0,21,9c11-7,26,3,22,16
                      c9-3,19,4,19,14c0,9-9,15-18,14c3,18-18,29-32,17C234.8,174.8,228.8,175.8,222.8,175.8z M157.8,197.8l2-18l-15-12c-1,0-10-1-13-4
                      C138.8,176.8,143.8,185.8,157.8,197.8z M169.8,205.8l18-30c-2-4-3-8-2-12c-4,2-8,2-12,2C169.8,180.8,167.8,191.8,169.8,205.8z
                      M193.8,181.8c-8,17-9,15,8,3C198.8,184.8,195.8,183.8,193.8,181.8z M279.8,104.8c-2,3-4,5-7,6c-5-6-14-10-22-8c-5-6-13-9-21-8
                      c-11-13-31-14-42-1c-4-14-20-22-33-16c-10-18,4-36,22-31c3-2,7-5,11-6c-1-12,11-23,24-20c10-23,44-12,39,12c24-1,38,19,28,32
                      c14-2,27,6,28,18C307.8,95.8,294.8,106.8,279.8,104.8z"
                    />
                  </g>
                </g>
              </g>
            </svg>

            <svg
              v-else
              src="@/assets/img/Baum_3_4.png"
              class="partly-tree"
              :style="thumbnailSize"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 333 333"
              style="enable-background:new 0 0 333 333;"
              xml:space="preserve"
            >
              <g id="Ebene_1">
                <g>
                  <path
                    class="st0"
                    d="M144,66c-17-12-45-7-54,13c-7-1-14,1-20,5c-3-2-6-3-9-6c-9,6-22,1-24-11c-1-9,6-18,15-18c4-12,15-21,28-21
                    c5-21,34-25,44-6c5-3,12-4,18-1c6-14,25-16,33-3c5-3,11-2,15,2c-4,4-7,10-8,17c-2,1-5,2-7,3C159,38,145,51,144,66z M114,194l18,17
                    c-3-6-4-11-6-17l4-3c7,15,10,28,23,37c0-10,0-9,1-19c-14-15-21-26-30-42c-9,2-17-3-21-10c-1,0-2,1-3,1L114,194z M223,176l-34,29
                    c-12,9-5,89,6,120h-56c6-23,10-60,13-82c-3-3-5-6-7-9l-38-37l-14-37c-6,0-13-3-17-9c-7,17-35,15-37-5c-17-3-17-27,0-29
                    c-3-15,13-30,29-21c5-10,17-13,27-8c5-29,51-29,56,0c18-14,33,1,31,21c-4,2-7,4-10,8c-29,3-38,37-10,53l2-6c-22-11-13-43,13-41
                    c2-6,7-8,12-9c-4-22,28-31,38-11c8-4,18,0,21,9c11-7,26,3,22,16c9-3,19,4,19,14c0,9-9,15-18,14c3,18-18,29-32,17
                    C235,175,229,176,223,176z M158,198l2-18l-15-12c-1,0-10-1-13-4C139,177,144,186,158,198z M170,206l18-30c-2-4-3-8-2-12
                    c-4,2-8,2-12,2C170,181,168,192,170,206z M194,182c-8,17-9,15,8,3C199,185,196,184,194,182z M280,105c-2,3-4,5-7,6
                    c-5-6-14-10-22-8c-5-6-13-9-21-8c-11-13-31-14-42-1c-4-14-20-22-33-16c-10-18,4-36,22-31c3-2,7-5,11-6c-1-12,11-23,24-20
                    c10-23,44-12,39,12c24-1,38,19,28,32c14-2,27,6,28,18C308,96,295,107,280,105z"
                  />
                </g>
              </g>
              <g id="Ebene_2">
                <g>
                  <defs>
                    <rect id="SVGID_1_" x="13.5" y="83.8" width="301.8" height="241.2" />
                  </defs>
                  <clipPath id="SVGID_2_">
                    <use xlink:href="#SVGID_1_" style="overflow:visible;" />
                  </clipPath>
                  <g class="st1">
                    <path
                      class="st2"
                      d="M143.8,65.8c-17-12-45-7-54,13c-7-1-14,1-20,5c-3-2-6-3-9-6c-9,6-22,1-24-11c-1-9,6-18,15-18
                      c4-12,15-21,28-21c5-21,34-25,44-6c5-3,12-4,18-1c6-14,25-16,33-3c5-3,11-2,15,2c-4,4-7,10-8,17c-2,1-5,2-7,3
                      C158.8,37.8,144.8,50.8,143.8,65.8z M113.8,193.8l18,17c-3-6-4-11-6-17l4-3c7,15,10,28,23,37c0-10,0-9,1-19c-14-15-21-26-30-42
                      c-9,2-17-3-21-10c-1,0-2,1-3,1L113.8,193.8z M222.8,175.8l-34,29c-12,9-5,89,6,120h-56c6-23,10-60,13-82c-3-3-5-6-7-9l-38-37
                      l-14-37c-6,0-13-3-17-9c-7,17-35,15-37-5c-17-3-17-27,0-29c-3-15,13-30,29-21c5-10,17-13,27-8c5-29,51-29,56,0c18-14,33,1,31,21
                      c-4,2-7,4-10,8c-29,3-38,37-10,53l2-6c-22-11-13-43,13-41c2-6,7-8,12-9c-4-22,28-31,38-11c8-4,18,0,21,9c11-7,26,3,22,16
                      c9-3,19,4,19,14c0,9-9,15-18,14c3,18-18,29-32,17C234.8,174.8,228.8,175.8,222.8,175.8z M157.8,197.8l2-18l-15-12c-1,0-10-1-13-4
                      C138.8,176.8,143.8,185.8,157.8,197.8z M169.8,205.8l18-30c-2-4-3-8-2-12c-4,2-8,2-12,2C169.8,180.8,167.8,191.8,169.8,205.8z
                      M193.8,181.8c-8,17-9,15,8,3C198.8,184.8,195.8,183.8,193.8,181.8z M279.8,104.8c-2,3-4,5-7,6c-5-6-14-10-22-8c-5-6-13-9-21-8
                      c-11-13-31-14-42-1c-4-14-20-22-33-16c-10-18,4-36,22-31c3-2,7-5,11-6c-1-12,11-23,24-20c10-23,44-12,39,12c24-1,38,19,28,32
                      c14-2,27,6,28,18C307.8,95.8,294.8,106.8,279.8,104.8z"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </template>
        </div>
        <p class="text" v-html="treesText"></p>
      </div>
      <div class="co2-comparision-flight">
        <div class="image-container">
          <svg
            version="1.1"
            class="flight-map image-height"
            id="Ebene_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            style="enable-background:new 0 0 100 100;"
            xml:space="preserve"
          >
            <g id="Ebene_3">
              <path class="st0" d="M49.3,49.9c0,0-50.1-0.4-48.5-7.2" />
              <path class="st0" d="M49.3,52.6c0,0-50.1-0.4-48.5-7.2" />
              <path class="st0" d="M51,50.2c0,0,50.1-0.4,48.5-7.2" />
              <path class="st0" d="M51,52.6c0,0,50.1-0.4,48.5-7.2" />
            </g>
            <g>
              <g>
                <path
                  class="st1"
                  d="M27.1,89.4c0.3-0.8,0.5-0.7,0.8-1.4c0.3-0.7-0.4-1.5,0.1-2s1.5-1.1,1.5-1.1s0.3-0.2,0.8-0.4
                  c0.5-0.2,1.1-0.1,1.3-0.4c0.2-0.4,0.4-1.1,0.6-1.6c0.3-0.4,0.6-1.1,0.4-1.4c-0.1-0.3,0.4-1.6,0.3-2c0-0.4-0.4-1.2,0.2-1.9
                  c0.7-0.7,1.4-1.8,1.4-2.1c0-0.3,0.6-1.6,0.5-2.2c0-0.6-0.5-1.3-0.8-1.5s-1-0.2-1.2-0.7c-0.3-0.5-1-1-1.3-0.8
                  c-0.3,0.1-0.7,0.2-1.1-0.1c-0.4-0.3-0.9-0.7-1.2-0.9c-0.2-0.2-0.8-0.4-1.1-0.4c-0.3,0-0.2-0.2-0.6,0.5s0,1-0.7,0.8
                  c-0.7-0.1,0.1-0.2,0.5-0.9c0.4-0.7,0-1.1-0.5-1c-0.4,0.2-0.1,0.5-0.9,0.7c-0.7,0.2-0.7-0.4-0.3-0.5c0.4-0.1,1.3-0.7,1.3-0.9
                  c0-0.2-0.1-0.9-0.4-0.9c-0.4,0-0.3-0.6-0.8-1.4s-1.2-1.3-1.7-1.1c-0.5,0.1-0.9,0.3-1.2-0.4c-0.3-0.7-0.6-0.8-0.8-1.1
                  c-0.2-0.4-0.6,0-0.8-0.2c0-0.1,0-0.1,0-0.1s-0.6,0.2-0.3-0.4c0.3-0.5,0.4-1.6,0.3-1.5C21.3,60.2,21,61,20.8,61s0-0.2-0.5-0.5
                  c-0.4-0.4-1.2-0.1-1.2,0.2s-0.2,0.5-0.2,0.5s-0.1-0.2-0.3-0.5c-0.1-0.4-0.8-0.4-1.1-0.1c-0.3,0.4-0.5-0.9-0.7-0.9
                  s-0.7,0.3-0.9,0.8c-0.2,0.5-0.1,1.3-0.3,1.3c-0.2-0.1-0.2-1.1-0.2-1.4c0-0.4,0.5-1,0.2-1.1c-0.4-0.1-0.9,0.6-1.1,0.7
                  c-0.2,0.1-0.7,0.5-0.9,1c-0.3,0.5-0.4,0.8-0.7,1s-0.7,0-0.6-0.2c0.1-0.2-0.8-0.8-0.9-0.6c-0.1,0.2-0.5,0.4-0.9,0.7
                  c-0.4,0.2-0.8-0.3-0.8-0.5c-0.1-0.2-0.3-0.3-0.3-1.1s0.1-1.6,0.2-2.1c0.1-0.5-0.7-1.4-1-1.4C8.3,56.7,8,57.1,7.7,57
                  c-0.3,0-0.2-0.1-0.7-0.1s-0.1-0.1,0-0.7s-0.2-1.3,0.3-1.7c0.5-0.5,0.3-1.6,0-1.7C7,52.7,6.5,53,6.1,52.9s-0.7,0.4-0.6,1
                  c0.1,0.5-0.2,0.9-0.4,0.8c-0.1-0.1-0.3-0.4-0.8,0.1s-0.7,0.3-1.2-0.2s-1-1.1-1.1-1.9c-0.1-0.8-0.1-1.7,0.1-2.2
                  C2.3,50,2.3,49.2,2,49c-0.3-0.1,0.3-0.7,0.6-0.8s0.8-0.7,0.9-0.9c0.1-0.2,0.5-0.2,1-0.2s0.6,0,1.1,0.4s0.6-0.2,0.6-0.4
                  s0.2-0.5,0.7-0.5c0.5,0.1,1.3-0.2,1.4-0.1c0.1,0.2,0.4,0.9,0.7,0.7s0.4-0.7,0.4,0.1c0.1,0.7,0.3,1.8,0.7,2s0.2,0.9,0.6,1
                  c0.4,0.1,0.6-1.1,0.4-1.6c-0.1-0.4-0.4-1.7-0.6-2c-0.2-0.3-0.2-1.1,0.2-1.3s1.5-1.4,1.8-1.8c0.3-0.4,1.2-0.7,0.9-1.1
                  C13.1,42,13,41.7,13,41.4c0-0.4,0.3-0.2,0.4,0c0.1,0.2,0.7-0.4,0.5-0.7c-0.1-0.3-0.1-0.7,0.2-0.7c0.3,0.1,0.4-0.3,0.3-0.5
                  c-0.1-0.2,0.3-0.5,0.6-0.4s1.1-0.1,1.2-0.3s-0.3-0.5-0.3-1s0.5-0.9,0.8-1c0.3-0.1,0.7,0,0.8-0.2c0-0.2,0.5-0.7,0.5-0.2
                  c0,0.4,0,1.1,0.4,1.1s0.8-0.5,0.9-0.6s1-0.3,1.2-0.4c0.2-0.1,1.2-0.2,1.1-0.6c-0.1-0.4-0.3-0.8-0.4-1s-0.3,0.1-0.4,0.4
                  c-0.2,0.3-0.5,0.3-0.6,0.1s0-1-0.3-0.7c-0.3,0.2-0.4,0.5-0.5,0.4C19.2,35,19,34.6,19,34.6s0.4-0.3,0.1-0.8s-0.8-0.4-1.2-0.1
                  c-0.4,0.3-0.4,0.2-0.9,0.4s-0.8,0.4-0.8,0.4s0.7-0.6,1-1s0.7-0.5,1-0.9s1,0.2,1,0.2s0.4-0.5,0.4-0.2s-0.1,1.1,0.2,1.1
                  c0.3,0,1-0.5,0.7-0.6s0.4,0,0.8-0.3c0.3-0.3,0.7-0.3,0.8-0.4c0.2-0.1,0.3-0.7,0.6-0.7c0.3,0,0.4,0.5,0,0.9s-0.5,1.3-0.7,1.5
                  s-0.1,0.5,0.3,0.5c0.4-0.1,0.7,0.1,1-0.1c0.3-0.1,0.5,0.1,0.6,0.3s0.4-0.2,0.4-0.2s0.6-0.2,0.8,0.1c0.2,0.3,0.3-0.2,0.2-0.5
                  c-0.1-0.3-0.3-0.6-0.4-1s-0.3-0.5-0.7-0.4c-0.4,0.1-0.4-0.1-0.7-0.3c-0.2-0.2-0.4,0.2-0.2-0.2c0.2-0.4,0.3-0.5,0.4-0.9
                  s0.7-1,0.1-1.2S23,29.9,23,29.5c-0.1-0.4-0.8-1-0.9-1c-0.2,0-0.8,0-0.8-0.3s-0.5-1-0.7-1.3s-1.1-1.6-1.2-1.7s-0.3-0.1-0.7,0.7
                  c-0.4,0.7-0.8,0.8-1.1,0.7c-0.3-0.2-0.4-0.1-0.7-0.6c-0.3-0.5,0.3-0.5,0.2-0.8c-0.1-0.3-0.8-0.4-1.3-0.5c-0.4-0.1-0.8-0.7-1.4-0.8
                  c-0.7-0.1-1.5,0.1-1.7,0.1s-0.2,0.4-0.3,1.1s0.3,0.8-0.1,1c-0.4,0.1-0.6,0.2-0.2,0.4c0.4,0.2,0.9,1.2,0.9,1.2s0,0.5-0.3,0.8
                  s-0.6,0.6-0.8,0.2s-0.7-0.3-0.5,0.1c0.1,0.4,0.4,0.5,0.4,0.9s0.4,1.3,0.1,1.6c-0.3,0.3-0.6,0.1-0.8,0.1c-0.2,0.1-0.5-0.5-0.3-0.6
                  s0-1,0-1s-0.5,1-0.5,0.5s-0.2-0.6-0.2-1s-0.3-0.6-0.7-0.5s-0.9-0.2-1.6-0.4C7,28.1,6.7,28,6.1,27.6S5.3,27.4,5,27.5
                  C2.4,33.4,1,40,1,46.8c0,3.4,0.3,6.7,1,9.9c0.2,0.2,0.5,0.8,0.8,0.4c0.3-0.4,0.3-0.6,0.7-0.6s0.6,0.4,0.8,0.7
                  c0.2,0.3,0.8,1.1,1.2,1c0.3-0.1,0.7,0.5,0.9,0.3c0.2-0.2,0.6,0.1,0.8,0.2C7.3,59,8,59.5,7.9,60.2c0,0.7,0.4,0.9,0.6,1.1
                  c0.3,0.2,0.6,0.6,0.8,0.9c0.2,0.3,0.7,0.4,0.8,0.5c0.1,0.2,0.2,0.5,0.6,0.2c0.4-0.3,0.3-0.8,0.3-0.8s0.4-0.1,0.7,0.1
                  c0.2,0.2,0.4,1,0.5,1.1s0.4,1,0.2,1.7s-0.1,1.3-0.3,1.3s-0.4,0.8-0.7,1s-1,1-0.9,1.3c0,0.3,0.5,1.2,0.4,1.6s-0.7,0.9-0.5,1.6
                  c0.1,0.7,1.1,0.8,1.1,1.4s0.7,1.9,0.8,2.5s0.8,1.3,0.8,2.1c0,0.8,1.1,1.4,1.3,1.4c0.2,0.1,0.6,0.6,0.9,0.8c0.3,0.2,0.6,0.5,0.8,1
                  c0.2,0.5,0.2,1.2,0.2,1.4c3,2.8,6.3,5.2,9.9,7.2l0.1-0.1C26.7,89.5,26.8,90.2,27.1,89.4z M12.2,36.3c0.1,0.1,1.1-0.2,1.2,0.1
                  c0.2,0.3-0.2,0.4-0.6,0.6c-0.4,0.2-0.6,0.2-0.8,0.5s-0.8,0.4-1,0.8c-0.3,0.4-0.7,0.6-0.8,0.3c-0.1-0.4,0-0.2,0.1-0.4
                  c0-0.2,0.9-0.6,0.9-0.6S12.1,36.2,12.2,36.3z M9.8,34.9c0.1-0.1,0.8-0.1,1.1,0s0.8,1.1,0.5,1.2c-0.2,0.1-0.6-0.2-0.6-0.7
                  c0-0.4-0.3,0.8-0.1,1.1c0.2,0.4-0.4,0.8-0.5,0.8s-0.3-0.5-0.3-1.1c0-0.5-0.3-1-0.8-1c-0.5,0.1-0.7,0.6-0.9,0.8S8,37.2,8.1,37.4
                  c0,0.2,0,1.1-0.4,1S7.4,37.3,7.4,37c0-0.4-0.1-1.3,0-1.7c0.2-0.5,0.7-0.7,1.1-0.7C8.9,34.6,9.7,35,9.8,34.9z M6.3,33.3
                  c0.6-0.3,1.2-1.1,1.5-0.8c0.3,0.4,0.8,0.8,1.1,0.8c0.3,0.1-0.1,0.8-0.2,0.8c-0.1,0.1-0.6-0.1-1-0.1c-0.4,0-0.3-0.4-0.5-0.3
                  c-0.2,0.1-0.6,0.2-0.8,0.4c-0.3,0.2-0.7,0.1-0.7-0.2C5.6,33.5,5.7,33.6,6.3,33.3z"
                />
                <path
                  class="st1"
                  d="M9.4,21.1c0,0,0.5-0.2,0.7-0.3c0.1-0.1,0.8-0.2,0.7-0.7s-0.6-0.5-0.7-0.7c-0.1-0.2,0.3-0.4,0.5-0.5
                  s0.8,0,0.8,0s0.4,0.1,0.4-0.2c0-0.2-0.1-0.5,0.2-0.4c0.3,0.2,0,0.7,0.4,0.6s0.5,0,0.7,0.2s0,0.9-0.3,1c-0.2,0.1-0.6,0.4-0.1,0.4
                  c0.5,0.1,0.8,0.1,0.8,0.1s0.3-0.4,0.4-0.8c0.1-0.4,0.4-0.1,0.5,0c0.2,0.1,0.8,0.3,0.2,0.5s-0.8,0.5-0.9,0.8
                  c-0.1,0.3-0.2,0.5-0.8,0.3s-0.9-0.1-1,0.2c-0.1,0.3,0,1.3,0.2,1.1c0.2-0.1,0.4-0.1,0.7-0.2s1.3,0.1,1.6,0.1
                  c0.3-0.1,0.7,0.6,0.8,0.5s0.4,0.2,0.7,0.5c0.3,0.2,0.2-0.5,0.7-0.1c0.5,0.5,0.2,0.5,0.5,0.5s0.9,0.1,0.9,0.1s0-0.6,0.5-0.5
                  c0.4,0.1,0.8,0.8,0.8,0.3s0-0.8-0.3-1.2c-0.4-0.4-0.9-0.6-1-0.8c-0.1-0.2-0.2-0.4,0-0.5c0.1-0.1,0.8,0.1,1.1,0.4
                  c0.4,0.3,0.7,0.6,0.9,0.4c0.3-0.2,0.6-0.4,0.7-0.5c0.1-0.2,0.4-0.7,0.4-0.7s-1.3-0.2-1.5-0.5s-0.8-0.7-1-0.7s-0.7,0.4-0.8-0.2
                  c-0.2-0.6-0.2-1.3-0.4-1.4c-0.2-0.1-2.1-0.4-2.3-0.6c-0.3-0.2-1.3-0.6-1.7-0.9s-1-0.7-1.3-0.7H12c-1.3,1.6-2.6,3.4-3.7,5.2
                  c0,0,0,0,0.1,0C8.9,20.9,9.4,21.1,9.4,21.1z"
                />
                <path
                  class="st1"
                  d="M8.1,22.7c-0.4,0.4-0.2,0.5,0.1,0.5s0.9-0.4,0.9-0.4s0.7-0.4,0.9-0.1c0.2,0.4,0.2,0.7,0.5,0.7s1-0.5,0.4-0.9
                  s-0.9-0.4-1.3-0.8c-0.4-0.5-0.3-0.3-0.7-0.2c-0.4,0.1-0.4-0.2-0.5-0.2s-0.2,0-0.3,0C8,21.6,7.9,21.8,7.7,22
                  C8,22.3,8.4,22.4,8.1,22.7z"
                />
                <path
                  class="st1"
                  d="M9.3,23.7c-0.1,0.2,0.5,0.4,0.7,0.2C10.1,23.6,9.6,23.2,9.3,23.7z"
                />
                <path class="st1" d="M11,24c-0.5,0.2,0,0.6,0.3,0.5S11.5,23.9,11,24z" />
                <path
                  class="st1"
                  d="M45.2,21.6c-0.2-0.3-0.8-0.5-1.1-0.5s-0.5,0.3-1.1,0.3s-1,0.3-1.2,0s-1.2-0.5-1.3-0.3
                  c-0.1,0.3-0.4,0.3-0.1,0.8c0.2,0.6,0.4,0.9,0.6,0.8c0.2,0,0.9-0.1,1,0.2c0.1,0.3,0.3,0.6,0.8,0.4s0.9-0.3,1-0.6
                  c0.1-0.2,0.9-0.3,0.9-0.3S45.4,21.8,45.2,21.6z"
                />
                <path
                  class="st1"
                  d="M40.9,15.7c0-0.6,0-1.3,0.2-1.7s0.5-0.6,0.3-0.7c-0.2-0.1-0.4,0.2-0.6-0.4c-0.2-0.6,0.2-1,0.4-1.3
                  c0.2-0.2,1.4-1.8,2-2s1.1-1.2,0.6-1.4c-0.4-0.2-1.3-0.6-1.9,0.1c-0.6,0.6-0.9,1.2-1.6,1c-0.7-0.2-0.8-1.3-1.1-1.8
                  c-0.5-0.8-0.2-2.4-0.8-2.2s-0.6,0.1-0.6,0.1s-0.7-0.2-1.1,0c-0.5,0.2-0.9,0.6-1.2,0.5c-0.6-0.2-1.2,0.2-1.3,0.3
                  c-1.3,0.4-2.4-0.1-2.6-0.2c-0.2,0,0.9,0.2,1.8,0c0.6-0.1,1.7-0.4,2.3-0.5C36.2,5.3,35.8,5,35.6,5c-0.7-0.1-1.7-0.5-2.6-0.5
                  c-0.6,0-1.1,0.2-1.7,0.2c-0.8,0-1.8,0-2.5,0.5c-0.6,0.5-1.3,0.5-1.4,0.6c-0.3,0.5-0.6,1.7-0.9,1.4s-0.4-1.1-0.7-0.8
                  c-0.4,0.3,0,1.1-0.4,1.7c-0.3,0.6-1.2,0.5-1.5,0.3C23.7,8.1,23.4,8,22.3,8c-1.1,0.1-2.3,0.6-2,1c0.2,0.5-0.5,0.8-0.7,0.5
                  c-0.2-0.2-1.1,0.3-1,0.8s-0.1,1-0.6,0.9s-0.8,0.3-0.8,0.7s-1.1,0.7-1.1,0.7c-0.1,0-1.4,0-0.9,0.4c0.3,0.3,0.5,0.1,0.9,0.2
                  c0.4,0.1,0.7,1,0.9,1.1c0.2,0.1,0.5,0,0.7-0.2C17.8,13.9,18,13,18,13s0,0.8,0.3,1s1.1-0.1,1.4-0.1c0.2,0,1.5,0.3,1.9,0.5
                  s1.2,0.4,1.5,0.9c0.2,0.5,0.3,2.3,0.4,2.5s0.6-0.6,0.7-0.8c0.1-0.2,0.7-0.1,0.9,0.2c0.2,0.3,0.6,1,0.6,1.2l0,0l0,0
                  c0,0.3-0.1,0.8-0.3,0.6c-0.1-0.1-0.3,0-0.3-0.2v-0.1c0.1-0.3,0.2-0.7-0.1-0.7c-0.2,0-0.4,0-0.4,0h-0.1l0,0l-0.1-0.1l0,0
                  c0,0,0,0-0.1,0S24.1,18,24,18.2c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.3,0.1,0.4,0.2,0.4c0.2,0,0.6,0,0.6,0.2l0,0l0,0
                  c0,0.2-0.4,0.6-0.4,0.9s0.4,1.2,0.5,1.4c0.1,0.2,0.7,0.4,0.8,0.4l0,0c0,0-0.2,0.1-0.3,0.2l0,0v0.1c0.1,0.3,0.4,1,0.7,1.1
                  c0.3,0.1,0.9,0.7,1.2,1.1l0,0l0.1,0.1l0,0c0,0.1,0.1,0.1,0.1,0.1c0.1,0,0.3-0.1,0.4-0.2l0,0l0,0c-0.1,0-0.2-0.1-0.2-0.1l0,0l0,0
                  l0,0l0.3-0.3c0,0,0,0,0.1-0.1c0-0.1,0.1-0.2,0.1-0.2c0.1,0,0.1,0,0.1,0s-0.1,0.1-0.2,0.2c0,0,0,0.1-0.1,0.1v0.3V24
                  c0,0,0.1-0.3,0.2-0.3s0.1,0,0.1,0L27.9,24l-0.3,0.3v-0.6h0.3h0.2l0.1,0.3l0.1,0.2v0.1v0.1v0.1c0,0.1,0,0.2,0.1,0.2
                  c0,0.1,0.1,0.2,0.1,0.2l0,0c0,0.1,0.1,0.1,0.2,0.1l0,0l0,0c0.1,0,0.2,0,0.3,0l0,0c0.1,0,0.1-0.1,0.2-0.1l0.1-0.1
                  c0.1-0.1,0.1-0.1,0.2-0.2c0.3-0.4,0.3-0.5,0.3-0.9c0-0.3,0-0.2,0.2-0.2l0,0c0,0,0.1-0.4,0.2-0.3c0.1,0,0.2-0.1,0.2-0.1
                  c0.2,0,0.3-0.3,0.3-0.6c0-0.2-0.1-0.5-0.1-0.7s0-0.4,0.3-0.5s0.6-0.3,0.8-0.4c0.1-0.1,0.1-0.1,0.2-0.1c0.1-0.1,0.2-0.1,0.3-0.1
                  c0.4,0,1.3,0.2,1.4,0c0.1-0.2,0.6-1.1,0.9-1.2c0.3,0,1.1-0.1,1.8-0.3c0.7-0.2,1.5-0.8,1.7-0.7c0.2,0,0.6,0,0.7-0.1v-0.1
                  c-0.2-0.2-0.5-0.8-0.9-0.8s0-0.9,0.1-0.9s0.7,0.1,0.6,0.5c0,0.4,0.2,0.9,0.7,0.8c0.5-0.2,0.5-0.8,0.4-0.9
                  c-0.1-0.2-0.2-0.6-0.3-0.8c-0.1-0.3-0.1-0.6,0.5-0.7C40.1,16,40.9,16.4,40.9,15.7z"
                />
                <path
                  class="st1"
                  d="M13,52.9c-0.3,0.1-1-0.4-1.3-0.8c-0.4-0.4-1-0.4-1.3-0.4s-1.3,0.4-1.3,0.4c0,0.6,0.4,0.4,0.9,0.4
                  s1.5-0.1,1.6,0.2c0.2,0.3,1,0.8,1.2,1s0.9,0.3,1,0.1C13.8,53.5,13.3,52.8,13,52.9z"
                />
                <path
                  class="st1"
                  d="M12.4,51c0.5-0.1,0.2-0.7-0.2-0.6C11.8,50.4,11.9,51.1,12.4,51z"
                />
                <path
                  class="st1"
                  d="M12.5,55.4c-0.4,0.1-0.3,1.2,0.2,1C13.1,56.1,12.9,55.3,12.5,55.4z"
                />
                <path
                  class="st1"
                  d="M18.4,54.7c-0.4,0.1-0.3,1.2,0.2,1C19,55.5,18.8,54.6,18.4,54.7z"
                />
                <path
                  class="st1"
                  d="M16.4,53.9c-0.4-0.1-0.8,0-1,0.1c-0.3,0.1-1,1-1,1s0.5,0.1,0.8,0.1s0.5,0.2,0.8,0.4c0.3,0.2,0.8-0.2,0.9-0.2
                  c0.2,0,0.4,0.7,0.4-0.1S16.7,54,16.4,53.9z"
                />
                <path
                  class="st1"
                  d="M70.2,46.2c-0.1-0.3-0.3-0.6-0.6-0.5c-0.5,0.2-1.2,0.2-1.7,0.9c-0.4,0.7-0.6,0.8-0.7,0.5
                  c-0.1-0.2,1.3-1.3,1.1-1.6c-0.2-0.3-1.3-0.2-1.8-0.2c-0.6,0-1.1-0.5-1.5-0.6s-1,0.6-1.5,1.2c-0.4,0.6-0.8,0.2-1.2,0.2
                  s-1.2-0.8-1.2-0.8s-0.7-0.3-1-0.3c-0.3,0-0.9-0.8-1.1-0.8c-0.2,0-0.7-0.5-0.3-0.8c0.4-0.2,0.7,0.2,0.4-1.1c-0.4-1.3-1.2-1-1.5-0.8
                  s-2.5,0.1-2.7,0.1s-1.5,0.6-1.9,0.8s-1.1,0.5-1.3,0.7c-0.2,0.1-1.1-0.2-1.5,0.1c-0.5,0.4-1,1-1.2,1c-0.1,0-0.5,0.4-0.8,1.3
                  c-0.3,0.9,0,1.7-0.4,2c-0.5,0.3-1.4,1-1.7,1.4c-0.4,0.5-0.8,2.2-1.2,2.9c-0.4,0.8,0,1.5,0.1,2s0.4,1.4-0.1,2.2s-0.4,1.3-0.7,1.3
                  c-0.2,0.1,0.2,0.3,0.3,0.9c0.1,0.6-0.4,1.2,0.4,1.7c0.8,0.5,1.3,1,1.5,1.7s1.3,1.9,1.3,1.9s0.8,1,1.2,1.4s0.5,0.2,0.6-0.2
                  c0.1-0.4,0.6-0.5,1.2-0.5c0.6,0,1.1,0.2,1.7,0.1c0.6-0.1,1.3-0.7,1.5-0.8s1-0.8,1.6-0.3s0.5,1.1,0.8,1.6c0.3,0.4,0.9,0.2,1.2-0.2
                  c0.4-0.4,0.2-0.2,0.7,0.1c0.5,0.4,0.7,0.7,0.3,1.3c-0.4,0.5-0.2,1.4-0.4,2.2c-0.3,0.8-0.2,1,0.3,1.3c0.5,0.4,1.4,2.6,1.6,3
                  c0.3,0.4-0.1,1.6,0.2,2.3c0.3,0.7,0.6,1.2,0.3,1.6c-0.4,0.4-1,1-1,2.2s0.2,2,0.4,2.5c0.2,0.4,0.6,1.6,0.8,2s0.5,1.4,0.5,2.1
                  s-0.1,1.4,0.1,1.8c0.2,0.4,0.4,0.9,0.8,1.4c0.4,0.5,0.5,1.5,0.5,1.5s0.1,0.4,0.2,1.1c0,0.7,0.1,1.1,0.5,1.4s0.8,0.4,1,0.1
                  s1.3-0.4,1.9-0.3s1.2-0.4,1.8-0.9c0.6-0.5,1.4-2,1.7-2.4c0.3-0.4,1-1.2,0.9-1.7s-0.3-1,0.1-1.2s1.2-0.7,1.2-1.1s-0.3-1.9-0.4-2.2
                  c-0.1-0.3,0.2-0.8,0.6-1.4s1.5-1,1.6-1.3c0.2-0.2,0.7-1.3,0.8-2c0.1-0.8-0.2-1.7,0-2.2c0.2-0.4,0-0.8-0.4-1.3s-0.3-1.9-0.4-2.2
                  c-0.1-0.2,0-1.5,0.2-1.6c0.3-0.1,0.8-1.3,1.2-1.9c0.4-0.5,1.4-2.2,1.9-2.3c0.5-0.1,1.2-1.1,1.3-1.6c0-0.5,1.3-2.6,1.4-3
                  c0-0.4,0.6-2,0.4-2.4s-2,0.5-2.2,0.7c-0.2,0.1-0.8,0.6-1.6,0.5c-0.7-0.1-0.4-0.8-0.4-1.2s-0.8-1.7-1-1.8s-0.7-0.8-0.9-1.5
                  c-0.2-0.7-0.5-1.6-0.9-2.2c-0.4-0.5-0.6-1.8-0.9-2.6c-0.4-0.8-0.7-1.9-1-2.7c-0.1-0.2-0.2-0.4-0.2-0.6 M70.2,68
                  c0.1-0.3,0.9-0.4,1-0.1c0.2,0.5-0.5,2-0.9,1.9C69.9,69.7,70,68.3,70.2,68z"
                />
                <path
                  class="st1"
                  d="M78.3,78.2c-0.4,0.7-0.9,1.2-1.1,1.1s-1.2,0.4-0.8,1.3c0.3,0.9,0.3,1.8,0,2.2c-0.3,0.4,0.3,1.6,0.2,2.3
                  c-0.1,0.7,0.1,1.1,0.8,1.1c0.6,0,0.8-0.3,1.1-1.1c0.3-0.8,0.3-1.6,0.6-2.2c0.3-0.7,0.5-2,0.6-2.4s0.4-1.5,0.4-2.2
                  s-0.2-1.5-0.5-1.6C78.8,76.6,78.7,77.6,78.3,78.2z"
                />
                <path
                  class="st1"
                  d="M95.5,61.3c-0.3,0.2-0.5,2.2,0,2.5c0.5,0.2,0.8-1,0.7-1.3C96,62.2,95.9,61.1,95.5,61.3z"
                />
                <path
                  class="st1"
                  d="M58.4,39.9c0.2-0.4-0.2-1-0.4-1c-0.4,0-0.6,0.9-0.4,1.2C57.9,40.4,58.1,40.3,58.4,39.9z"
                />
                <path
                  class="st1"
                  d="M55,39.9c0.1-0.2-0.1-0.7-0.4-0.1C54.5,40.1,54.9,40.1,55,39.9z"
                />
                <path
                  class="st1"
                  d="M60.4,41.5c0.2,0.1,0.4,0.5,0.6,0.5c0.2,0.1,0.8,0,0.6-0.6s-0.6-0.6-0.7-0.6c-0.1,0-0.7-0.2-0.9,0
                  C59.7,41,60.1,41.4,60.4,41.5z"
                />
                <path
                  class="st1"
                  d="M48.4,31.8c0.4,0.1,0.8-0.3,1.1-0.5s0.8-0.5,0.8-1.1c0-0.5-0.1-1-0.3-1.3c-0.2-0.2-0.5-0.3-0.8-0.2
                  c-0.4,0.1-0.4,0.7-0.7,0.7s-0.6,0.1-0.5,0.5s0.3,0.7,0,0.9C47.7,31,48,31.7,48.4,31.8z"
                />
                <path
                  class="st1"
                  d="M50.6,29.2c0.4,0.2,1.4,0.5,1,0.7s-1,0.9-1.1,1.3s0.8,0.7,0.8,0.7s-0.3,0.4,0,0.5c0.2,0.1,0.4-0.4,0.7-0.4
                  c0.4,0,1.1,0.3,1.4,0.3c0.4,0,0.9-0.7,0.9-1.1s-0.5-1.1-0.9-1.3c-0.4-0.2-0.9-1.3-1.1-1.6c-0.3-0.2,0.3-0.8,0-1.3
                  c-0.3-0.4-0.5-0.4-0.9-0.7c-0.4-0.3-0.7,0.1-0.9,0.4c-0.2,0.4-0.5-0.1-0.8,0.1c-0.4,0.2-0.2,0.8,0,1.1
                  C49.8,28.3,50.1,28.9,50.6,29.2z"
                />
                <path
                  class="st1"
                  d="M91.7,20.9c-0.1,0-1.1,0.6-1.2,0.5s0.4-0.8,0.7-1.1c0,0,0-0.1,0.1-0.1C90.6,19.1,89.8,18,89,17
                  c-0.1,0.1-0.2,0.2-0.4,0.2c-0.4,0.1-0.7,0.8,0,1.2c0.7,0.4,0.9,0.7,0.7,1c-0.2,0.2-0.9-0.1-0.9-0.1s-0.6-0.4-0.9-0.4
                  c-0.4,0-0.8-0.3-1.2-0.4c-0.4-0.1-1.1-0.1-1.2,0.2c-0.2,0.2-0.6,1-1,0.7c-0.4-0.2-0.4-0.5-0.9-0.2c-0.5,0.2-1-0.2-1.2,0.2
                  c-0.3,0.4,0,0.4-0.9,0c-0.8-0.4-0.7,0.2-1.5,0.4c-0.8,0.2-1.3-0.2-1.5,0.2c-0.2,0.4-0.5,1-0.7,0.6c-0.1-0.4,0.4-1.1,0.2-1.4
                  c-0.2-0.2-1.2-0.1-1.3,0.1c-0.1,0.2,0,0.8,0,1.1c0.1,0.2,0.2,1.2,0,1c-0.3-0.2-0.2-0.8-0.5-0.7c-0.3,0.1-0.6,0.2-0.7,0.4
                  c-0.1,0.1-0.7,0.1-0.8,0.4c-0.1,0.2,0,0.9-0.2,1s-0.4,0.3-0.9-0.1s-0.5-0.2-0.6-0.1s-0.8,0.6-0.8,0.1s0.1-0.7-0.3-1.1
                  s-0.1-0.4,0.4-0.1c0.5,0.2,1.2,0.4,2.2-0.1c1-0.4,1.4-0.6,1-0.8c-0.4-0.2-0.8-0.5-1.4-0.6s-1.5-0.1-2.5-0.6s-1.2-0.7-1.6-0.8
                  c-0.4-0.1-2.9-0.7-4-0.4c-1.1,0.2-4.2,1.3-4.4,1.5s-0.4,0.9-1,1.5s-1.7,2-2.5,2.2C57,23.2,56,24.2,56,24.4s0.4,1.1,0.3,1.3
                  c-0.1,0.2,0.2,1.1,0.7,1.3c0.5,0.2,1.3,0.2,1.4-0.2c0-0.4,0.7-1.1,0.8-0.6c0,0.5,0.5,1.4,0.4,1.7c-0.1,0.4,0,0.7,0.3,0.7
                  c0.3-0.1,2-0.5,2-0.5s0.5-0.3,0.4-0.8s0.6-1.1,0.7-1.3c0.1-0.2,0.6-0.9,0-1.1s-1-0.3-0.4-1s1,0,1.8-1.1c0.8-1.1-0.2-1.4,0.7-1.4
                  c0.8-0.1,1.7-0.1,1.6,0.2s-1.2,1.1-1.8,1.5c-0.1,0.1-0.2,0.2-0.3,0.3l-0.2,0.1c0,0.1-0.3,0.2-0.3,0.3l0,0l0.2,0.1
                  c0,0,0.1,0,0.1,0.1l0.1,0.1v0.1v0.1l0,0c0,0.1,0.1,0.2,0.1,0.3l0,0c0.2,0.3,0.3,0.8,0.5,1l0,0l0,0h0.1c0.4-0.1,0.9-0.5,1.3-0.5
                  c0.1,0,0.2,0,0.4,0c0.4,0,1-0.1,1.1-0.1c0,0,0,0,0.1,0l0,0l0,0c0.1,0,0.1,0.2,0.2,0.3v0.2l0,0v-0.2c0.1,0.1,0.2,0.3,0.2,0.4
                  s-0.1,0.1-0.1,0.2L68.3,26l0,0v-0.5h0.1h0.1l-0.1,0.3L68.3,26l0,0l0,0l0,0c0,0.1,0.1,0.2,0,0.3c-0.4,0.1-0.7,0.7-1.4,0.7h-0.1
                  v-0.2c0,0-0.7-0.2-1-0.2c-0.1,0-0.1,0-0.2,0c0.1,0.1-0.1,0.2,0,0.3c0,0.1-0.2,0.1-0.2,0.2l0,0h0.3l0.1,0.1V27c0.1,0.1-0.1,0-0.1,0
                  h-0.3v0.4c0,0,0.3-0.1,0.2-0.1c-0.4,0.3-0.6-0.1-1-0.2c-0.3,0-0.5,0.3-0.6,0.7l0,0l0,0c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.1,0,0.2
                  s0,0.2,0,0.2l0,0l0,0c0,0.1,0,0.2,0,0.2v0.1l0,0l0,0c0,0.1,0,0.2,0,0.2l0,0c0,0.1-0.1,0.1-0.2,0.1v0.2v0.2l0,0l0,0
                  c-0.1,0-0.1-0.2-0.2-0.3c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1,0-0.3-0.1-0.4-0.1c-0.2,0-0.4,0-0.5,0.1c-0.2,0.1-0.7,0.5-1.2,0.7
                  c-0.1,0-0.2,0.1-0.2,0.1l0,0h-0.1l0,0l0,0l0,0h-0.1l0,0c-0.1,0-0.2,0-0.3,0c-0.6,0-0.6,0-0.6,0l-0.4-0.2c0,0-0.7-0.4-0.7-0.9
                  l-0.3-0.1l0,0c0-0.3,0.4-0.7,0.4-0.9c0-0.1,0.1-0.1,0.1-0.2c0,0-0.1,0-0.2,0c-0.3,0-0.7,0.2-0.8,0.4c-0.1,0.3-0.2,0.7-0.2,1
                  c0,0.1,0,0.2,0.1,0.3c0.1,0.2,0.5,0.6,0.4,0.7c0,0,0,0-0.1,0h-0.1c-0.2-0.1-0.5-0.2-0.8-0.1c-0.3,0-0.5,0.1-0.7,0.3
                  c-0.4,0.4-1,1.1-1,1.1s-1.1,0.1-1.1,0.5c0,0.4,0,0.8-0.4,0.9c-0.4,0.1-0.9,0.2-1.5,0.4c-0.5,0.1-0.5,0.4-1.1,0.5s-0.7,0.4-0.1,0.5
                  c0.6,0.1,1.5,0.6,1.5,1.1s0,1.1-0.3,1.7c-0.2,0.4-1.1,0.1-1.6,0.1s-1.6-0.2-2,0s-0.4,1.4-0.2,2c0.1,0.5,0,0.8-0.2,1s-0.2,0.7,0,1
                  c0.2,0.2,0.4,0.8,0.6,0.8c0.2,0.1,0.8-0.2,1,0.1s0.4,1,0.6,0.8c0.1-0.2,0.3-1,0.5-0.8s0.8-0.1,1.2-0.1c0.4-0.1,0.6-0.7,1-1
                  s0.2-0.3,0-0.7c-0.1-0.4,0.4-0.8,0.7-1.2s0.9-0.1,1-0.5c0.1-0.5,0.2-1.3,0.5-1.3c0.4,0.1,0.8,0.4,1.2,0.4c0.3,0,1-0.7,1.2-0.8
                  s0.8-0.1,0.9,0.5c0.1,0.5,0.8,1.2,1,1.3s1.3,0.6,1.5,0.8s0.4,0.7,0.5,1.2s0.4,0.5,0.4,0.1s-0.2-1.2,0.1-1.1
                  c0.3,0.1,0.7-0.1,0.7-0.1s-0.9-0.8-1.2-1s-0.9-0.5-1.2-1.1s-0.9-0.7-0.9-1.3s0.7-0.2,1,0.2c0.4,0.5,1.2,1.1,1.6,1.6
                  c0.3,0.4,0.8,0.6,0.9,1.1c0.1,0.5,0.5,1.1,0.7,1.4c0.1,0.2,0.6,1.6,0.7,1.9c0.1,0.3,0.6-0.1,0.8-0.3c0.2-0.2,0.6-0.4,0.7-0.8
                  c0-0.5-0.5-0.8-0.7-1s0.4-0.4,0.7-0.6c0.2-0.2,0.6-0.7,0.8-0.3c0.2,0.4,0.1,1.8,0.2,2s1,0.6,1.2,0.9c0.2,0.3,0.9,0.4,1.1,0.2
                  c0.1-0.2,0.8,0.1,1,0.2c0.2,0.1,0.7-0.1,1.1-0.2c0.4-0.1,0.7-0.5,0.7,0.3s-0.2,2-0.6,2.6s-0.3,0.9-0.7,0.9c-0.2,0-0.4,0-0.5,0.1
                  c0.1,0.3,0.2,0.7,0.3,0.9c0.1,0.3,0.3,0.7,0.6,1.3c0.4-0.1,1-0.4,1.1,0s0.6,1.3,0.7,2s0.6,0.4,0.6,1s0.5,2,0.7,2.2s1,1.1,1.1,1.5
                  s0.3,0.8,0.4,1.7s0.3,2.1,0.6,2.3c0.4,0.2,1.6-0.3,1.8-0.5c0.2-0.2,1.7-1.1,2.2-1.2c0.5-0.1,0.6-0.5,1.3-0.7
                  c0.7-0.2,1.2-0.8,1.6-1.3s1-1.7,1.2-2.1c0.2-0.4,0.6-0.7,0.4-1.2s-1.2-1.2-1.5-1.3c-0.4-0.1-0.3-0.8-0.5-0.8s-0.2,0.4-0.6,0.7
                  s-0.9,0.9-1.2,0.8c-0.3-0.1,0.1-0.4-0.4-1c-0.5-0.5-0.7-0.7-0.7-1.1c0-0.4-1-1.2-1.1-1.8s0.7-1.4,0.9-0.9c0.2,0.5,0.4,1.4,1,1.7
                  c0.6,0.4,1.1,1,1.4,1.1s0.9-0.7,1.2-0.5c0.2,0.1,0.7,1.2,1.1,1.4s1.7,0.2,2,0.2s1.2,0.1,1.3-0.3c0.2-0.4,0.3-0.4,0.5-0.4
                  s0.6,1.1,0.9,1.2c0.3,0.1,0.9,0.1,0.8,0.8c0,0.8,0.3,1.4,0.6,1.5c0.3,0.1,1-0.9,1-0.9s0,1.5,0,2s0.5,2.5,0.5,2.5s0.7,1.9,0.8,2.3
                  c0.2,0.4,0.9,1.7,0.9,2.2s0.1,1.4,0.4,1.4s0.5-1,0.8-1.6s0.4-1.4,0.5-2s0.1-1.7,0.1-2.1c0-0.4,0.3-0.8,0.9-1.1
                  c0.6-0.4,1.1-1.4,1.6-1.9c0.3-0.3,0.4-0.7,0.7-1c0.3-2.1,0.4-4.2,0.4-6.4C99.2,37.3,96.4,28.4,91.7,20.9z M70.7,25.3
                  c-0.2,0.4-1.4,0-1.2-0.6C69.7,23.8,70.9,24.8,70.7,25.3z M75,38.8c-0.4,0.1-1.4,0.2-1.8,0.2c-0.4-0.1-0.9-0.6-1.5-0.7
                  c-0.6-0.1-1.3-0.1-1.5,0.4c-0.2,0.4-1,0.2-1.4,0.2s-0.8,0.6-0.9,0.4c0,0,0.3-1.1,0-1.2C67.6,38,68,37.3,68.3,37
                  c0.3-0.2,0.6-0.8,0.8-1.4c0.3-0.6,0.6-0.7,0.8-0.6c0.2,0.1,0.7,1.1,0.8,1.5c0.1,0.4,0.6-0.1,0.8-0.2c0.2-0.1,0.4-0.5,0.5-0.8
                  c0-0.3,0.4-0.5,0.9-0.5c0.4,0,0,0.5-0.3,0.6S72.5,36,73,36.2c0.5,0.2,1,0.9,1.6,1.3C75.3,37.8,75.4,38.7,75,38.8z M82,35.9
                  c-0.2,0.2-1,0.4-1.3,0s-0.9,0.1-0.9,0.7c0,0.7,0.2,0.2,0.4,0.4c0.3,0.2,0.4,0.6,0.5,1c0.2,0.4,0.5,0.2,0.7,0.5s-0.6,0.7-0.3,1
                  c0.4,0.4,0.5,1.8,0.6,2.2c0,0.4-1,0.5-1.3,0.5c-0.3-0.1-1.2-0.5-1.6-0.6c-0.3-0.1-0.5-1.6-0.1-1.6c0.4,0.1,0.5-0.1,0.9-0.7
                  c0.4-0.5-0.7-0.8-1-0.9c-0.3-0.1-0.9-1.4-1.2-1.9c-0.2-0.4,0-1.1,0.5-1.6c0.3-0.2,1,0.3,1.3,0c0.4-0.3,0.7-0.7,0.9-0.8
                  c0.1-0.1,1,0.2,1.2,0.5C81.6,35.1,82.2,35.7,82,35.9z"
                />
                <path
                  class="st1"
                  d="M82.6,15.3c-0.6,0.4-1.5,0.9-1.8,1.1c-0.3,0.2-0.6,0.9-0.4,1c0.3,0.1,0.8,0.2,1.3,0.5
                  c0.5,0.4,1.2,0.5,1.5,0.1s0-1-0.2-1c-0.2-0.1-0.4-0.5,0.2-1c0.6-0.4,1.2-1,1.9-1.1c0.5-0.1,1.4-0.1,2-0.1c-0.3-0.4-0.6-0.7-1-1.1
                  c-0.7,0.1-2.1,0.5-2.5,0.5C83.4,14.2,83.2,14.9,82.6,15.3z"
                />
              </g>
              <path class="st2" d="M50,34.4" />
              <path class="st2" d="M49.5,34.2" />
            </g>
            <g>
              <path class="st3" d="M52.1,42.6" />
              <path class="st3" d="M1.1,33" />
              <path class="st3" d="M50.2,35.5" />
              <path class="st0" d="M49.3,36.2c0,0-50.1,0.4-48.5,7.2" />
              <path class="st0" d="M49.3,36.2c0,0,51.9,0.6,50.2,9.5" />
              <path class="st0" d="M49.3,38.5c0,0-50.1,0.4-48.5,7.2" />
              <path class="st0" d="M56.1,34.1c0,0,44.8,0.6,43.4,9.5" />
            </g>
            <g id="Ebene_4">
              <g>
                <g>
                  <g>
                    <path
                      class="st4"
                      d="M49.6,41.6H50l1.7-2.4l1.6,0.2c0.9,0,1-0.5,1-0.7c0-0.2-0.2-0.7-1-0.7h-1.6L50,35.4h-0.3l0.5,2.6h-1.9
                      l-0.7-0.9h-0.2v1c-0.2,0-0.2,0.2-0.2,0.3c0,0.2,0,0.3,0.2,0.3v1h0.2l0.7-0.9l1.9,0.2L49.6,41.6z"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <p class="text" v-html="flightText"></p>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorWrapper from '@/components/Helper/ErrorWrapper'
import LoadingScreen from '@/components/Helper/LoadingScreen'

import contractUpdater from "@/mixins/contractUpdater";
import refreshTimer from "@/mixins/refreshTimer";
import portal_setting from "@/assets/settings/data.js";

import moment from "moment";

export default {
  name: "Co2Consumption",
  data: () => ({
    apiResponse: null,
    loading: true,
    errorboxOpen: false,
    errorboxMsg: '',
    heading: "",
    co2Saved: "",
    comparison: "",
    trees: "",
    flight: "",
    note: "",
    co2Data: async () => await import(`@/assets/co2/${portal_setting.co2_file}`),
  }),
  components: {
    ErrorWrapper,
    LoadingScreen,
  },
  mixins: [contractUpdater, refreshTimer],
  async created() {
    const {default: data} = await this.co2Data();
    this.heading = data.heading;
    this.co2Saved = data.text.co2Saved;
    this.comparison = data.text.comparison;
    this.trees = data.text.trees;
    this.flight = data.text.flight;
    // check if date is in the month of May 2022
    const todaysDate = moment().format("YYYY-MM-DD");
    if(moment(todaysDate).isBetween('2022-05-01', '2022-05-31', undefined, '[]'))
      this.note = data.text.note;
  },
  computed: {
    co2SavedText() {
      let co2SavedVariable = this.co2Saved;
      co2SavedVariable = co2SavedVariable.replace("co2_year", this.co2_year);
      co2SavedVariable = co2SavedVariable.replace("co2_saving", this.co2_saving);
      co2SavedVariable = co2SavedVariable.replace("co2Amount", this.$options.filters.formatFloat(this.co2Amount,'kg', 0));
      return co2SavedVariable;
    },
    treesText () {
      return this.trees.replace("treeAmount", this.$options.filters.formatFloat(this.treeAmountObject.treeAmount, 'Bäume', 2 ))
    },
    flightText () {
      return this.flight.replace("flightAmount", this.$options.filters.formatFloat(this.flightAmount, 'km', 0 ))
    },
    co2Amount() {
      if (!this.apiResponse || !this.apiResponse.consumption_total) return 0;

      return this.apiResponse.consumption_total.value.co2;
    },
    co2_saving() {
      if (!this.apiResponse || !this.apiResponse.consumption_total) return 0;

      return this.apiResponse.consumption_total.value.intensity_saving;
    },
    co2_year() {
      if (!this.apiResponse || !this.apiResponse.consumption_total) return "";

      return this.apiResponse.consumption_total.value.intensity_year;
    },
    flightAmount() {
      return this.co2Amount / 0.22;
    },
    thumbnailSize() {
      if (this.treeAmountObject && this.treeAmountObject.treeAmount <= 9) {
        return "width:96px;";
      }

      return "width:64px";
    },
    treeAmountObject() {
      let treeAmount = Math.round((this.co2Amount / 3000) * 100) / 100;
      return {
        treeAmount: treeAmount,
        wholeTrees: Math.trunc(treeAmount),
        partlyTree: treeAmount - Math.trunc(treeAmount)
      };
    }
  },
  filters: {
    formatFloat(value, unit = "", digits = 0) {
      if (!value) {
        if (unit) return "0 " + unit;
        return "0";
      }

      if (unit) {
        return (
          value.toLocaleString("de", {
            maximumFractionDigits: digits,
            minimumFractionDigits: digits
          }) +
          " " +
          unit
        );
      }

      return value.toLocaleString("de", {
        maximumFractionDigits: digits,
        minimumFractionDigits: digits
      });
    }
  },
  methods: {
    getData(startDate = null) {
      var path =
        this.$store.state.api.pathViewsV3 +
        "Contracts/" +
        this.selectedContract.hid +
        "/Measurements/Consumption/Total";

      if (this.$store.state.account.user.customers.length) {
        path +=
          "?customerId=" +
          this.$store.state.account.user.customers[
            this.$store.state.account.user.customers.length - 1
          ].customerInfo.id;
      }

      this.$http
        .get(path, {timeout: 45000})
        .then(response => {
          this.loading = false;
          this.apiResponse = response.data;
        })
        .catch(e => {
          this.errorboxMsg = e.message.startsWith('timeout')
            ? 'Die Datenabfrage dauert derzeit ungewöhnlich lang.'
            : undefined
          this.loading = false
          this.errorboxOpen = true
          console.error("%o", e);
        });
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshData();
    });
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/custom";

.text {
  color: $co2Primary;
}

.note-text {
  color: $co2svgColor;
  margin-top: 5rem;
  font-size: 80%;
}

#widget-co2-calc {
  position: relative;
  background-color: $co2BoxBackground;
  color: $white;
  display: flex;
  flex-flow: row wrap;

  @include media-breakpoint-between(xs, sm) {
    flex-flow: column;
  }

  > div.co2-savings,
  > div.co2-comparison {
    margin: auto;
    padding: 10px;

    > div {
      margin: 20px;
    }
  }

  > .co2-savings {
    width: 50%;
  }

  &> .co2-comparison >.co2-comparision-trees{
    max-width: 300px;
    margin: auto;
  }

  .image-container {
    svg {
      width: 100%;
      max-width: 350px;

      &.co2-cloud {
        fill: $co2svgColor;
      }

      &.whole-tree {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: $co2svgColor;
      }

      &.partly-tree {
        .st0 {
          fill-rule: evenodd;
          clip-rule: evenodd;
          fill: #ffffff;
        }
        .st1 {
          clip-path: url(#SVGID_2_);
        }
        .st2 {
          fill-rule: evenodd;
          clip-rule: evenodd;
          fill: $co2svgColor;
        }
      }

      &.flight-map {
        .st0 {
          fill: none;
          stroke: #ffffff;
          stroke-width: 0.5;
          stroke-miterlimit: 10;
        }
        .st1 {
          fill: $co2svgColor;
        } // map-color
        .st2 {
          fill: none;
          stroke: #000000;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        }
        .st3 {
          fill: none;
          stroke: #000000;
          stroke-width: 0.5;
          stroke-miterlimit: 10;
        }
        .st4 {
          fill-rule: evenodd;
          clip-rule: evenodd;
          fill: #ffffff;
        }
      }
    }
  }
}
</style>
