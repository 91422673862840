<template>
  <div class="module module-chart chart-consumption">
    <LoadingScreen :loading="loading" />
    <ErrorWrapper :isOpen="errorboxOpen" :msg="errorboxMsg" />
    <div class="chart-inner">
      <h2>
        Stromverbrauch
        <span v-if="currentView == 'months'"
          >im {{ displayedDate.format("MMMM") }},
          {{ displayedDate.format("Y") }}</span
        >
        <span v-else-if="currentView == 'years'"
          >in {{ displayedDate.format("Y") }}</span
        >
      </h2>

      <div class="label-box">
        <a
          v-if="currentView === 'months'"
          href="javascript:void(0)"
          class="label"
          @click="setView('years')"
          >Jahresansicht</a
        >
        <a
          v-else
          href="javascript:void(0)"
          class="label"
          @click="setView('months')"
          >Monatsansicht</a
        >
        <a
          v-if="dateNotFuture || currentView != 'months'"
          href="javascript:void(0)"
          class="label"
          @click="goToday"
          >Heute</a
        >
      </div>

      <br />
      <br />

      <div class="chart-navigation-outer">
        <div class="chart-navigation nav-back" data-action="back">
          <div ref="btnBack">
            <font-awesome-icon
              v-if="backEnabled"
              class="icon"
              icon="chevron-left"
            ></font-awesome-icon>
          </div>
        </div>
        <div class="chart-scroller-outer">
          <div class="chart-scroller">
            <BarChart
              chartId="chart-consumption-month-year"
              cssClasses="chart-canvas"
              :chartData="chartData"
              :options="chartOptions"
              :plugins="chartPlugins"
            ></BarChart>
          </div>
        </div>
        <div class="chart-navigation nav-forward" data-action="forward">
          <div ref="btnForward">
            <font-awesome-icon
              v-if="dateNotFuture"
              class="icon"
              icon="chevron-right"
            ></font-awesome-icon>
          </div>
        </div>
      </div>

      <div class="legend">
        <ul>
          <li v-if="showSolar" data-color="chart-solar">
            <span class="icon"></span>
            <span class="text">{{ solarText }}</span>
          </li>

          <li data-color="chart-consumption">
            <span class="icon"></span>
            <span class="text">{{ consumptionText }}</span>
          </li>

          <li data-color="chart-average">
            <span class="icon"></span>
            <span class="text">{{ averageText }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from "@/components/BarChart";
import ErrorWrapper from "@/components/Helper/ErrorWrapper";
import LoadingScreen from "@/components/Helper/LoadingScreen";

import chartjsPluginAnnotation from "chartjs-plugin-annotation";
import contractUpdater from "@/mixins/contractUpdater";
import moment from "moment";
import refreshTimer from "@/mixins/refreshTimer";
import theme from "@/assets/settings/theme.js";
import portal_setting from "@/assets/settings/data.js";

export default {
  name: "ChartConsumptionYearMonth",
  components: {
    BarChart,
    ErrorWrapper,
    LoadingScreen,
  },
  mixins: [contractUpdater, refreshTimer],
  data: () => ({
    apiResponse: undefined,
    apiResponseMonth: undefined,
    apiResponseYear: undefined,
    chartData: {},
    chartOptions: {},
    chartPlugins: [chartjsPluginAnnotation],
    currentView: "months",
    displayedDate: moment().startOf("day"),
    currentDate: moment().startOf("day"),
    navClicks: 0,
    loading: true,
    errorboxOpen: false,
    errorboxMsg: "",
    solarText: "",
    consumptionText: "",
    averageText: "",
    histogramData: async () => await import(`@/assets/overview/${portal_setting.histogram_file}`),
  }),
  async created() {
    const {default: data} = await this.histogramData()
    this.solarText = data.solar;
    this.consumptionText = data.consumption;
    this.averageText = data.average;
  },
  computed: {
    firstDatetime() {
      if (
        !this.apiResponse ||
        !this.apiResponse.values ||
        this.apiResponse.values.length === 0
      )
        return null;
      return this.$moment(this.apiResponse.values[0].date, "YYYY-MM-DD");
    },
    showSolar() {
      if (!this.apiResponse || !this.apiResponse.contract_type) return false;

      return (
        this.apiResponse.contract_type === "TenantElectricity" ||
        this.apiResponse.contract_type === "100.lessor"
      );
    },
    dateNotFuture() {
      if (
        this.displayedDate.unix() == this.currentDate.unix() ||
        this.displayedDate.isAfter(this.currentDate)
      )
        return false;
      return true;
    },
    backEnabled() {
      if (!this.selectedContract || !this.selectedContract.delivery_start)
        return false;
      let eOD = moment(this.selectedContract.delivery_start);

      if (
        this.currentView == "years" &&
        eOD.isSameOrAfter(this.displayedDate, "year")
      )
        return false;
      else if (eOD.isSameOrAfter(this.displayedDate, "month")) return false;

      return true;
    },
  },
  methods: {
    getData(timestamp = null, direction = null) {
      if (this.selectedContract) {
        var path =
          this.$store.state.api.pathViewsV3 +
          "Contracts/" +
          this.selectedContract.hid +
          "/Measurements/Consumption/";

        this.displayedDate = this.navigateData(direction);
        if (this.currentView == "months") {
          path +=
            "Month/" +
            this.displayedDate.format("Y") +
            "/" +
            this.displayedDate.format("MM");
        } else if (this.currentView == "years") {
          path += "Year/" + this.displayedDate.format("Y");
        }

        if (this.$store.state.account.user.customers.length) {
          path +=
            "?customerId=" +
            this.$store.state.account.user.customers[
              this.$store.state.account.user.customers.length - 1
            ].customerInfo.id;
        }

        this.$http
          .get(path, { timeout: 30000 })
          .then((response) => {
            this.loading = false;
            this.apiResponse = response.data;
            if (this.currentView === "months") {
              this.apiResponseMonth = this.apiResponse;
            } else {
              this.apiResponseYear = this.apiResponse;
            }
          })
          .catch((e) => {
            this.errorboxMsg = e.message.startsWith("timeout")
              ? "Die Datenabfrage dauert derzeit ungewöhnlich lang."
              : undefined;
            this.loading = false;
            this.errorboxOpen = true;
            console.error(this.name + ":", e);
          });
      }
    },
    setChartOptions(average = 0) {
      var colorWhite = this.$parent.getColor("white");

      this.chartOptions = {
        barPercentage: 0.5,
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          point: {
            radius: 0,
          },
        },
        tooltips: {
          enabled: true,
          mode: "label",
          callbacks: {
            title: function (tooltipItems, data) {
              return;
            },
            label: function (tooltipItems, data) {
              return tooltipItems.value + " kWh";
            },
          },
        },
        hover: {
          mode: "x",
          onHover: function (e) {
            if (this.getElementAtEvent(e).length)
              e.target.style.cursor = "pointer";
            else e.target.style.cursor = "default";
          },
        },
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
                color: theme.color_chart_grid,
                drawBorder: false,
              },
              ticks: {
                beginAtZero: true,
                fontColor: theme.color_chart_grid,

                autoSkip: true,
                maxRotation: 0,
                minRotation: 0,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                color: theme.color_chart_grid,
                drawBorder: false,
                zeroLineColor: colorWhite,
              },
              ticks: {
                beginAtZero: true,
                fontColor: theme.color_chart_grid,
                callback: (value, index, values) => {
                  var valueMin = this.getValueMin(values);
                  var valueMiddle = this.getValueMiddle(values);
                  var valueMax = this.getValueMax(values);

                  if (
                    value === valueMin ||
                    value === valueMiddle ||
                    value === valueMax
                  ) {
                    return value + " kWh ";
                  }

                  return "";
                },
              },
            },
          ],
        },
        annotation: {
          events: ["click"],
          annotations: [
            {
              // Durchschnitt
              drawTime: "afterDatasetsDraw",
              id: "average",
              type: "line",
              mode: "horizontal",
              scaleID: "y-axis-0",
              value: average || this.apiResponse.values[0].value,
              borderColor: this.$parent.getColor("chart-average"),
              borderWidth: 3,
              label: {
                display: false,
              },
            },
          ],
        },
        onClick: this.changeMonth,
      };
    },
    setView(view) {
      if (this.currentView !== view) {
        if (this.currentView === "months" && this.apiResponseYear) {
          this.apiResponse = this.apiResponseYear;
          this.currentView = view;
        } else if (this.currentView === "years" && this.apiResponseMonth) {
          this.apiResponse = this.apiResponseMonth;
          this.currentView = view;
        } else {
          this.currentView = view;
          this.loading = true;
          this.refreshData(true);
        }
      }
    },
    getValueMin(values) {
      if (!values) return null;

      var valueMin = null;
      $.each(values, function (key, value) {
        if (!valueMin || value < valueMin) {
          valueMin = value;
        }
      });

      return valueMin;
    },
    getValueMiddle(values) {
      if (!values) return null;

      var valueMiddle = null;
      var valueCheck = this.getValueMax(values) / 2;
      var valuesReverse = values.slice(0).reverse();
      $.each(valuesReverse, function (key, value) {
        if (value >= valueCheck) {
          valueMiddle = value;
          return false;
        }
      });

      return valueMiddle;
    },
    getValueMax(values) {
      if (!values) return null;

      var valueMax = null;
      $.each(values, function (key, value) {
        if (!valueMax || value > valueMax) {
          valueMax = value;
        }
      });

      return valueMax;
    },
    changeMonth(event, array) {
      if (this.currentView == "years" && !(array.length <= 0)) {
        this.loading = true;
        this.setView("months");
        this.refreshData(this.displayedDate.set("month", array[0]._index));
      }
    },
    navigateData(direction) {
      if (!this.displayedDate || !this.selectedContract) return undefined;

      const eOD = moment(this.selectedContract.delivery_start);
      const dNB = this.displayedDate
        .clone()
        .subtract(this.navClicks, this.currentView);
      if (direction == "back")
        if (!dNB.isBefore(eOD))
          return this.displayedDate
            .clone()
            .subtract(this.navClicks, this.currentView);
        else return eOD; // prevent goint to much into history

      const dNF = this.displayedDate
        .clone()
        .add(this.navClicks, this.currentView);
      if (!dNF.isAfter(this.currentDate))
        return this.displayedDate.clone().add(this.navClicks, this.currentView);
      else return this.currentDate; // prevent going into the future
    },
    goToday() {
      this.displayedDate = this.currentDate;
      this.loading = true;

      if (this.currentView == "years") this.setView("months");
      this.refreshData(this.displayedDate);
    },
    cSaver(element, direction) {
      try {
        if (!element instanceof HTMLElement) {
          console.error("The object ${element} is not a valid HTML element!");
          return;
        }
      } catch (e) {
        console.error("Something wrong happened: ${e}");
        return (
          typeof obj === "object" &&
          obj.nodeType === 1 &&
          typeof obj.style === "object" &&
          typeof obj.ownerDocument === "object"
        );
      }

      let active = null;
      let cnt = 0;
      let dirFunc = this.navigateData(direction);
      element.addEventListener("click", () => {
        cnt += 1;
        clearTimeout(active);
        active = setTimeout(() => {
          this.navClicks = cnt;
          this.refreshData(dirFunc, direction);
          this.navClicks = cnt = 0;
        }, 500);
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshData();
    });

    this.cSaver(this.$refs.btnBack, "back");
    this.cSaver(this.$refs.btnForward, "forward");
  },
  watch: {
    apiResponse(newApiResponse, oldApiResponse) {
      if (!newApiResponse.values || newApiResponse.values.length === 0)
        return {};

      var labels = [];
      var powerSolars = [];
      var powerGrids = [];

      let dateFormat = "YYYY-MM";
      if (this.currentView === "years") {
        dateFormat = "YYYY";
      }

      if (
        this.$moment(newApiResponse.values[0].date).format(dateFormat) !=
        this.$moment(
          newApiResponse.values[newApiResponse.values.length - 1].date
        ).format(dateFormat)
      ) {
        newApiResponse.values.pop(-1);
      }

      $.each(newApiResponse.values, (index, value) => {
        if (this.showSolar) {
          powerSolars.push(value.power_solar.toFixed(2));
        }
        powerGrids.push(value.power_grid.toFixed(2));

        labels.push(
          this.$moment(value.date, "YYYY-MM-DD").format(
            this.currentView === "months" ? "D" : "MMM"
          )
        );
      });

      if (
        !oldApiResponse ||
        newApiResponse.average !== oldApiResponse.average
      ) {
        this.setChartOptions(newApiResponse.average);
      }

      this.chartData = {
        datasets: [
          {
            data: powerSolars,
            backgroundColor: this.$parent.getColor("chart-solar"),
          },
          {
            data: powerGrids,
            backgroundColor: this.$parent.getColor("chart-consumption"),
          },
        ],
        labels: labels,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@coreui/coreui/scss/functions";
@import "~@coreui/coreui/scss/variables";
@import "~@coreui/coreui/scss/mixins/_breakpoints";
@import "~@/assets/scss/custom";
@import "~@/assets/scss/components/consumption";

h2 {
  font-family: $font-bold;
}
</style>
