<script>
import { Line, mixins } from 'vue-chartjs'

export default {
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: {
    options: {
      default () { return {} },
      type: Object
    },
    chartId: {
      default: 'line-chart',
      type: String
    },
    width: {
      default: 400,
      type: Number
    },
    height: {
      default: 400,
      type: Number
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object
    },
    plugins: {
      type: Array,
      default () {
        return []
      }
    }
  },
  mounted () {
    this.addPlugin({
      id: 'empty-chart',
      afterDraw: function(chart) {
        if (chart.data.datasets[0].data.length === 0) {
          chart.clear();
        }
      }
    })
    
    
    if (this.chartData) {
      this.renderChart(this.chartData, this.options)
    }
  }
}
</script>
