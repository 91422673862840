import Vue from 'vue'
import Router from 'vue-router'
import Cockpit from './views/Cockpit.vue'
import Invoices from './views/Invoices.vue'
import Login from './views/Login.vue'
import Overview from './views/Overview.vue'
import Faq from '@/views/Faq.vue'
import PasswordForget from './views/PasswordForget.vue'
import PasswordReset from './views/PasswordReset.vue'

import EinhundertAdmin from './views/EinhundertAdmin.vue'
import CustomerManager from './components/CustomerManager.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'login',
      meta: { title: 'Login' },
      component: Login
    },
    {
      path: '/admin',
      name: 'admin',
      meta: { title: 'Admin' },
      component: EinhundertAdmin,
    },
    {
      path: '/overview',
      name: 'overview',
      meta: { title: 'Overview' },
      component: Overview
    },
    {
      path: '/faq',
      name: 'faq',
      meta: { title: 'FAQ' },
      component: Faq
    },
    {
      path: '/cockpit',
      name: 'cockpit',
      meta: { title: 'Cockpit' },
      component: Cockpit
    },
    {
      path: '/invoices',
      name: 'invoices',
      meta: { title: 'Invoices' },
      component: Invoices
    },
    {
      path: '/password-forget',
      name: 'password_forget',
      component: PasswordForget,
      meta: { title: 'Forgot Password' },
      alias: '/passwort-vergessen'
    },
    {
      path: '/password-reset',
      name: 'password_reset',
      meta: { title: 'Restore Password' },
      component: PasswordReset,
      alias: '/passwort-zuruecksetzen'
    }
  ]
})
