import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-table/dist/bootstrap-table.min.css'

import './jquery.js'
import 'popper.js'
import 'bootstrap'
import 'bootstrap-table/dist/bootstrap-table.js'
import 'bootstrap-table/dist/locale/bootstrap-table-de-DE.js'

import Vue from 'vue'
import BootstrapTable from 'bootstrap-table/dist/bootstrap-table-vue.esm.js'
Vue.component('BootstrapTable', BootstrapTable)
