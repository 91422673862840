<template>
  <div
    class="module module-chart chart-half chart-costs"
    :data-status="costStatus"
  >
    <LoadingScreen :loading="loading" />
    <ErrorWrapper :isOpen="errorboxOpen" :msg="errorboxMsg" />
    <div class="chart-inner">
      <h2>{{ headingText }} </h2>
      <div class="chart-canvas">
        <div class="doughnut-center">
          <div class="days text">
            1.
            <template v-if="daysElapsed > 1">- {{ daysElapsed }}.</template>
          </div>
          <div class="month text">{{ monthText }}</div>
          <div class="now">
            <span class="dCost">{{ currentCost | formatCurrency }}</span>
            <span class="text">von {{ costLimit | formatCurrency }}</span>
          </div>
        </div>

        <DoughnutChart
          chartId="chart-consumption-costs"
          cssClasses="chart-canvas"
          :chartData="chartData"
          :options="chartOptions"
          :width="1"
          :height="1"
        ></DoughnutChart>
      </div>

      <p class="foot text">
        <template v-if="costStatus === 'high'"> {{ highText }} </template>
        <template v-else-if="costStatus === 'middle'"> {{ mediumText }} </template>
        <template v-else> {{ lowText }} </template>
      </p>
    </div>
  </div>
</template>

<script>
import DoughnutChart from "@/components/DoughnutChart";
import ErrorWrapper from "@/components/Helper/ErrorWrapper";
import LoadingScreen from "@/components/Helper/LoadingScreen";

import contractUpdater from "@/mixins/contractUpdater";
import refreshTimer from "@/mixins/refreshTimer";
import theme from "@/assets/settings/theme.js";
import portal_setting from "@/assets/settings/data.js";
// import { donutData } from "./DonutDummyData.ts";

export default {
  name: "ChartConsumptionCosts",
  components: {
    DoughnutChart,
    ErrorWrapper,
    LoadingScreen
  },
  mixins: [contractUpdater, refreshTimer],
  data: () => ({
    apiResponse: null,
    chartOptions: null,
    loading: true,
    errorboxOpen: false,
    errorboxMsg: "",
    lowText: "",
    mediumText: "",
    highText: "",
    headingText: "",
    leftDonutData: async () => await import(`@/assets/overview/${portal_setting.left_donut_file}`),
  }),
  computed: {
    chartData() {
      if (!this.apiResponse) {
        return {};
      }

      var data = [];
      var backgroundColors = [];
      var costRest = this.apiResponse.cost_limit;

      var colorState = this.getColorFromStatus(this.apiResponse.cost_status);
      var colorGrey = this.$parent.getColor("grey-light");
      var colorGreenDark = this.$parent.getColor("green-dark");

      $.each(this.apiResponse.values, function() {
        var value = this;
        data.push(value.cost);
        backgroundColors.push(colorState);

        costRest -= value.cost;
      });

      if (costRest > 0) {
        data.push(costRest);
        backgroundColors.push(colorGrey);
      }

      return {
        datasets: [
          {
            data: data,
            backgroundColor: backgroundColors,
            borderColor: theme.doughnut_border_color
          }
        ]
      };
    },
    currentCost() {
      if (!this.apiResponse) return "Keine Daten empfangen.";

      return this.apiResponse.cost;
    },
    costLimit() {
      if (!this.apiResponse) return "Keine Daten empfangen.";

      return this.apiResponse.cost_limit;
    },
    costStatus() {
      if (!this.apiResponse) return "low";

      return this.apiResponse.cost_status;
    },
    daysElapsed() {
      if (!this.apiResponse) return 1;

      return this.apiResponse.days_elapsed;
    },
    monthText() {
      if (!this.apiResponse) return "Keine Daten empfangen.";

      return this.$moment(this.apiResponse.month, "M").format("MMMM");
    }
  },
  async created() {
    const {default: data} = await this.leftDonutData()
    this.headingText = data.heading;
    this.lowText = data.text.low;
    this.mediumText = data.text.medium;
    this.highText = data.text.high;
    this.chartOptions = {
      cutoutPercentage: 75,
      responsive: true,
      legend: false,
      animation: {
        animateScale: true,
        animateRotate: true
      },
      tooltips: {
        enabled: false
      },
      hover: {
        mode: null
      }
    };
  },
  filters: {
    formatCurrency(value) {
      if (!value) return "Keine Daten empfangen.";

      return (
        value.toLocaleString("de", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        }) + " €"
      );
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshData();
    });
    $('[data-toggle="tooltip"]').tooltip();
  },
  methods: {
    getColorFromStatus(status) {
      if (status === "high") {
        return this.$parent.getColor("status-warn");
      } else if (status === "middle") {
        return this.$parent.getColor("status-middle");
      }
      return this.$parent.getColor("status-ok");
    },
    getData(timestamp = null) {
      if (this.selectedContract) {
        var path =
          this.$store.state.api.pathViewsV3 +
          "Contracts/" +
          this.selectedContract.hid +
          "/Measurements/Overview";
        const customers = this.$store.state.account.user.customers;
        if (customers.length) {
          path +=
            "?customerId=" + customers[customers.length - 1].customerInfo.id;
        }

        this.$http
          .get(path, { timeout: 30000 })
          .then(response => {
            this.loading = false;
            this.apiResponse = response.data;
            // To show dummy data -->
            // this.apiResponse = donutData;
          })
          .catch(e => {
            this.errorboxMsg = e.message.startsWith("timeout")
              ? "Die Datenabfrage dauert derzeit ungewöhnlich lang."
              : undefined;
            this.loading = false;
            this.errorboxOpen = true;
            console.error(this.name + ":", e);
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@coreui/coreui/scss/functions";
@import "~@coreui/coreui/scss/variables";
@import "~@coreui/coreui/scss/mixins/_breakpoints";
@import "~@/assets/scss/custom";
@import "~@/assets/scss/components/consumption_doughnut";
</style>
