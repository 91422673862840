import 'core-js/es/promise'
import 'core-js/es/string'
import 'core-js/es/array'

import Vue from 'vue'
import App from './App.vue'
import { dom, library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowCircleDown, faArrowCircleUp, faBars, faCalendarAlt, faChartLine, faChevronLeft,
  faChevronRight, faCloud, faCloudDownloadAlt, faComments, faFileInvoice, faInfoCircle,
  faHome, faProjectDiagram, faTrash, faUniversity, faUserCog, faUsers, faPlus, faMinus, faCaretRight, faCaretDown,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BootstrapVue from 'bootstrap-vue'
import SvgIcon from 'vue-svgicon'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import router from './router'
import store from './store'
import './plugins/table.js'
import { httpBase } from '@/assets/js/HttpBase'
import moment from 'moment'

Vue.prototype.$ = $
Vue.prototype.$http = httpBase
Vue.config.productionTip = false

moment.locale('de')
Vue.use(require('vue-moment'), {
  moment
})

Vue.use(BootstrapVue)

Vue.use(SvgIcon, { tagName: 'svgicon' })

dom.watch()
library.add(
  faArrowCircleDown,
  faArrowCircleUp,
  faBars,
  faCalendarAlt,
  faChartLine,
  faChevronLeft,
  faChevronRight,
  faCloud,
  faCloudDownloadAlt,
  faComments,
  faFileInvoice,
  faHome,
  faProjectDiagram,
  faTrash,
  faUniversity,
  faUserCog,
  faUsers,
  faPlus,
  faMinus,
  faInfoCircle,
  faCaretRight,
  faCaretDown,
)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.filter('formatFloat', (value: number, unit: string = '', digits: number = 0) => {
  if (!value) {
    if (unit) return '0 ' + unit
    return '0'
  }

  if (unit) {
    return (value).toLocaleString('de', {
      maximumFractionDigits: digits,
      minimumFractionDigits: digits
    }) + ' ' + unit
  }

  return (value).toLocaleString('de', {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits
  })
})

if (process.env.NODE_ENV == "production") {
  Sentry.init({
    Vue,
    dsn: "https://5ff640f263fc4d45bc761799b1983d55@o581301.ingest.sentry.io/5738015",
    integrations: [new Integrations.BrowserTracing()],
    logErrors: true,
    environment: (process.env.VUE_APP_API_URL == 'http://t100api.einhundert-energie.de/') ? 'testing' : 'production',
    tracesSampleRate: (process.env.VUE_APP_API_URL == 'http://t100api.einhundert-energie.de/') ? 1.0 : 0.0,
    tracingOptions: {
      trackComponents: true,
    },
  });
}

new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate() {
    this.$store.commit('initializeStore')
  }
}).$mount('#app')
