<template>
  <nav class="navbar navbar-expand-xl fixed-top">
    <router-link class="navbar-brand" :to="{ name: 'overview' }">
      <img :src="require(`@/assets/img/logos/${company_logo}`)" width="100%" />
    </router-link>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarContent"
      aria-controls="navbarContent"
      aria-expanded="false"
      aria-label="Vertragsauswahl"
      v-click-outside="hide"
    >
      <font-awesome-icon
        class="icon navbar-toggler-icon"
        icon="bars"
      ></font-awesome-icon>
    </button>
    <div class="collapse navbar-collapse" id="navbarContent">
      <div class="d-flex flex-row flex-wrap justify-content-between navbar-nav">
        <template v-if="selectedContract">
          <router-link class="ml-auto nav-item nav-link" :to="{ name: 'overview' }"
          >
            <img v-if="site_url == 'einhundert-energie.de'" :src="require(`@/assets/icons/menu/${icon_prefix}-overview.svg`)" width="32px" height="32px" />
            <font-awesome-icon v-else class="icon" icon="chart-line"/>
            <span class="text">Übersicht</span>
          </router-link>
          <router-link class="ml-3 nav-item nav-link" :to="{ name: 'cockpit' }">
            <img v-if="site_url == 'einhundert-energie.de'" :src="require(`@/assets/icons/menu/${icon_prefix}-co2.svg`)" width="32px" height="32px" />
            <font-awesome-icon v-else class="icon" icon="cloud"></font-awesome-icon>
            <span class="text"> CO<sub>2</sub>-Rechner </span>
          </router-link>
          <router-link class="ml-3 nav-item nav-link" :to="{ name: 'invoices' }"
          >
            <img v-if="site_url == 'einhundert-energie.de'" :src="require(`@/assets/icons/menu/${icon_prefix}-invoice.svg`)" width="32px" height="32px" />
            <font-awesome-icon v-else class="icon" icon="file-invoice"/>
            <span class="text">Rechnungen</span>
          </router-link>
          <router-link class="ml-3 nav-item nav-link" :to="{ name: 'faq' }">
            <img v-if="site_url == 'einhundert-energie.de'" :src="require(`@/assets/icons/menu/${icon_prefix}-faq.svg`)" width="32px" height="32px" />
            <font-awesome-icon v-else class="icon" icon="comments"></font-awesome-icon>
            <span class="text">FAQ</span>
          </router-link>
          <router-link v-if="group === 'admin'" class="ml-3 nav-item nav-link" :to="{ name: 'admin' }"
          >
            <font-awesome-icon class="icon" icon="user-cog"></font-awesome-icon>
            <span class="text">Kundenverwaltung</span>
          </router-link>
          <template
            v-if="contractSelectItems && contractSelectItems.length > 1"
          >
            <div class="align-self-center dropdown ml-3 nav-item">
              <select
                class="btn btn-secondary dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
                @change="contractSelectChange()"
                v-model="selectedContractTmp"
              >
                <option
                  v-for="contract in contractSelectItems"
                  v-bind:key="contract.id"
                  v-bind:value="contract"
                >
                  {{
                    contract.contract_label
                      ? contract.contract_label
                      : contract.contract_number
                  }}
                </option>
              </select>
            </div>
          </template>
        </template>
        <div
          class="d-flex flex-column justify-content-center ml-auto user-info"
          v-if="userInfo"
        >
          <span v-if="firstName && lastName" class="username"
            >{{ firstName }} {{ lastName }}</span
          >
          <span v-else class="username">{{ username }}</span>
          <a href="javascript:" @click="logoutUser">Logout</a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import "bootstrap/js/dist/collapse.js";
import contractUpdater from "@/mixins/contractUpdater";
import portal_setting from "@/assets/settings/data.js";
import theme from "@/assets/settings/theme.js";
import ClickOutside from "vue-click-outside";

export default {
  name: "Navigation",
  data: () => ({
    company_logo: theme.menu_logo,
    faq_url: portal_setting.faq_url,
    site_url: portal_setting.site_url,
    selectedContractTmp: undefined,
    icon_prefix: theme.menu_icon_prefix,
  }),
  mixins: [contractUpdater],
  created() {
    if (this.currentCustomer) {
      if (!this.currentCustomer.contracts.length) {
        this.$store.dispatch("account/getCustomerContracts", this).then(() => {
          if (!this.currentCustomer.contracts.length) {
            this.selectedContractTmp = undefined;
          } else {
            this.selectedContractTmp = this.currentCustomer.contracts[0];
          }
        });
      } else {
        this.selectedContractTmp = this.currentCustomer.contracts[0];
      }
    } else if (
      this.$store.state.account.user.contracts &&
      this.$store.state.account.user.info.group !== "admin"
    ) {
      if (this.selectedContract) {
        this.selectedContractTmp = this.selectedContract;
      } else {
        this.selectedContractTmp = this.$store.state.account.user.contracts[0];
      }
    }

    if (this.selectedContractTmp) {
      this.contractSelectChange();
    }
  },
  computed: {
    contractSelectItems() {
      if (this.currentCustomer) {
        return this.currentCustomer.contracts;
      }
      return this.$store.state.account.user.contracts;
    },
    currentCustomer() {
      if (
        this.$store.state.account.user.customers &&
        this.$store.state.account.user.customers.length
      ) {
        return this.$store.state.account.user.customers[
          this.$store.state.account.user.customers.length - 1
        ];
      }
      return undefined;
    },
    group() {
      return this.userInfo.group ? this.userInfo.group : "customer";
    },
    userInfo() {
      if (this.currentCustomer) {
        return this.currentCustomer.customerInfo;
      }
      return this.$store.state.account.user.info;
    },
    firstName() {
      if (this.userInfo.main_user) {
        return this.userInfo.main_user.first_name;
      }
      return this.userInfo.first_name;
    },
    lastName() {
      if (this.userInfo.main_user) {
        return this.userInfo.main_user.last_name;
      }
      return this.userInfo.last_name;
    },
    username() {
      if (this.userInfo.main_user) {
        return this.userInfo.main_user.username;
      }
      return this.userInfo.username || "Unbekannt";
    },
  },
  methods: {
    contractSelectChange() {
      this.$store.commit("account/setContract", this.selectedContractTmp);
    },
    logoutUser() {
      if (this.currentCustomer) {
        this.$store.commit("account/popCustomer");
        if (!this.currentCustomer) {
          this.$router.replace({
            name: "admin",
          });
        }
      } else {
        this.$store.commit("account/logoutUser");
        window.localStorage.clear();
        this.$router.replace({
          name: "login",
        });
      }
    },
    hide() {
      if (this.$el.children[2].classList.contains("show")) {
        this.$el.children[1].click();
      }
    },
  },
  watch: {
    currentCustomer() {
      if (this.currentCustomer) {
        if (!this.currentCustomer.contracts.length) {
          if (this.$store.state.account.user.contracts.length) {
            let curContracts = [];
            this.$store.state.account.user.contracts.forEach((ele) => {
              if (
                ele.customer &&
                ele.customer.id === this.currentCustomer.customerInfo.id
              ) {
                curContracts.push(ele);
              }
            });
            if (curContracts) {
              this.$store.commit("account/setContracts", {
                results: curContracts,
              });
            }
          }

          if (!this.currentCustomer.contracts.length) {
            this.$store
              .dispatch("account/getCustomerContracts", this)
              .then(() => {
                this.selectedContractTmp = this.currentCustomer.contracts[0];
                this.contractSelectChange();
                return;
              });
          }
        }
        this.selectedContractTmp = this.currentCustomer.contracts[0];
        this.contractSelectChange();
      }
    },

    $route() {
      this.hide();
    },
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener("scroll", function () {
        var navbar = document.querySelector("nav");
        var nav_icon = navbar.childNodes[2].getElementsByClassName("icon");
        var nav_text = navbar.childNodes[2].getElementsByClassName("text");
        for (var i = 0; i < nav_icon.length; i++) {
          var icon_style = getComputedStyle(nav_icon[i]).fontSize;
          if (document.documentElement.scrollTop >= 30) {
            if (icon_style == "32px") {
              navbar.style.padding = "3px 7px";
              nav_icon[i].style.fontSize = "16px";
              nav_text[i].style.display = "initial";
            }
          } else {
            if (icon_style == "16px") {
              navbar.style.padding = "8px 16px";
              nav_icon[i].style.fontSize = "32px";
              nav_text[i].style.display = "block";
            }
          }
        }
      });
    });

    this.popupItem = this.$el;
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/custom";

select:focus {
  outline: none !important;
}

nav.navbar {
  background: $menuBackgroundColor;
  backdrop-filter: $contentBoxBlur;
  transition: 0.1s all ease-in-out;

  > div#navbarContent {
    > div.navbar-nav {
      width: 100%;
    }
  }

  .nav-link {
    color: $menuIconTextPrimary;
    transition: 0.2s all ease-in-out;
    &.router-link-active {
      filter: contrast(0);
      color: $menuIconTextAccent;
    }
    &:hover {
      filter: contrast(0);
      text-decoration: none;
      color: $menuIconTextAccent;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 3px;
    }
    > .icon {
      font-size: 2em;
      transition: 0.1s all ease-in-out;
    }
    > .text {
      display: block;
      transition: 0.1s all ease-in-out;
      font-family: $font-family-sans-serif;
      padding-left: 0.5em;
    }
  }
}

.navbar-brand {
  width: 12em;
  transition: all 0.1s ease-in-out;

  &:active {
    padding: 0 1px;
  }
}

.navbar-toggler-icon {
  color: $menuUserColor;
}

.user-info {
  line-height: 1.1em;
  font-size: 14px;
  text-align: right;
  text-transform: uppercase;
  color: $menuUserColor;
  .username {
    display: block;
    max-width: 250px;
  }
  a {
    color: $primary;
  }
}
</style>
