<template>
  <main class="c-main">
    <div class="container">
      <div class="module module-logo">
        <div class="inner"></div>
      </div>

      <div class="module module-form">
        <h1>Passwort zurücksetzen</h1>

        <p>Tragen Sie hier Ihre E-Mail Adresse ein, um einen Link zum Zurücksetzen Ihres Passworts zu erhalten.</p>
        <p>
          <router-link :to="{ name: 'login' }">Zurück zum Login</router-link>
        </p>

        <div
          v-if="error"
          class="alert alert-danger"
          role="alert"
        >Leider ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es später erneut.</div>

        <div
          v-if="success"
          class="alert alert-info"
          role="alert"
        >Jetzt einfach auf den Link klicken, den wir an die angegebene E-Mail Adresse gesendet haben.</div>

        <form @submit.prevent="queryPasswordEmail">
          <div class="form-group">
            <input
              type="text"
              v-model="email"
              placeholder="E-Mail"
              value
              class="form-control"
              required
            />
          </div>

          <div class="form-group">
            <input
              v-if="!email"
              type="submit"
              name="submit"
              value="Absenden"
              class="btn btn-primary btn-block"
              disabled
            />
            <input
              v-else
              type="submit"
              name="submit"
              value="Absenden"
              class="btn btn-primary btn-block"
            />
          </div>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
import json from "@/assets/settings/data.js";

export default {
  name: "password_forget",
  data: () => ({
    email: "",
    error: false,
    success: false
  }),
  created() {
    let companyLogoContainer = document
      .getElementsByClassName("module-logo")
      .getElementsByClassName("inner");
    if (companyLogoContainer.length) {
      companyLogoContainer[0].style.background = `url(@/assets/img/backgrounds/${json.menu_logo}) no-repeat center center`;
    }
  },
  methods: {
    queryPasswordEmail() {
      this.$http
        .post(
          this.$store.state.api.pathViews + "PasswordReset",
          `email=${encodeURIComponent(this.email)}`
        )
        .then(response => {
          this.success = true;
        })
        .catch(error => {
          if (error.response) {
            if (error.response.status === 404) {
              this.success = true;
              return;
            }
          }
          this.error = true;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/custom";

.container {
  max-width: 400px;
  margin: 0 auto;
}

.module-form {
  background: $another-60;
  padding: 70px 20px 5px;

  text-align: center;
  color: $accent;

  h1 {
    text-transform: uppercase;
    font-family: $font-bold;
    font-size: 32px;

    padding: 10px 0;
    color: $accent;
  }

  .alert {
    &.alert-danger,
    &.alert-info {
      border: none;
      color: $accent;

      a {
        color: $accent;
        text-decoration: underline;
      }
    }

    &.alert-danger {
      background: $red;
    }

    &.alert-info {
      background: $blue;
    }
  }

  input {
    border-color: $grey-light;
  }

  a {
    color: $primary;
    &:hover {
      color: $accent;
    }
  }

  .btn-primary {
    text-transform: uppercase;
    background: $primary;
    color: $accent;
    border: none;

    padding-top: 8px;
    padding-bottom: 8px;

    cursor: pointer;
    border-radius: 0;
  }
}

.module-logo {
  $size: 100px;

  width: $size;
  height: $size;

  margin: 0 auto;

  position: relative;
  top: 60px;

  .inner {
    @if $logo {
      background-image: url("~@/assets/img/logos/"+$logo);
      background-size: contain;
    }

    width: 100%;
    height: 100%;
  }
}
</style>
