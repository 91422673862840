import { MutationTree } from 'vuex'
import { AccountState } from './types'

import moment from 'moment'
import {
  einhundertApiAuthResponse,
  einhundertApiContractsResult,
  einhundertApiContractsResponse,
  einhundertApiUserResponse,
} from '@/assets/js/Api'

export const mutations: MutationTree<AccountState> = {
  logoutUser(state: AccountState) {
    state.credentials = undefined
    state.loginError = false
    state.user = {
      contracts: undefined,
      info: undefined,
      password: state.user.password,
      selectedContract: undefined,
      username: state.user.username,
      customers: []
    }
  },
  appendUser(state: any, user: einhundertApiUserResponse) {
    if (state.user.customers) {
      const id = user.customer_ids[0]
      if(!id){
        console.error("No customer id found in user", user);
      }
      state.user.customers.push({
        customerInfo: {
          id,
          last_name: user.last_name,
          first_name: user.first_name,
        },
        contracts: []
      })
    }
  },
  setAccountError(state: any, value: boolean) {
    state.loginError = value
  },
  setContracts(state: any, contractResponse: einhundertApiContractsResponse) {
    let curContracts
    if (contractResponse.length > 1) {
      curContracts = [{
        'contract_label': 'Alle Zähler',
        'contract_number': 'All',
        'contract_type': contractResponse[0].contract_type,
        'delivery_start': contractResponse[0].delivery_start,
        'contract_end': contractResponse[0].contract_end,
        'hid': 'All'
      }].concat(contractResponse)
    } else {
      curContracts = contractResponse
    }

    if (state.user.customers.length) {
      state.user.customers[
        state.user.customers.length - 1
      ].contracts = curContracts
    } else {
      state.user.contracts = curContracts
    }

    if (!state.user.selectedContract) {
      state.user.selectedContract = contractResponse[0]
    }
  },
  setContract(state: any, contract: einhundertApiContractsResult) {
    state.user.selectedContract = contract
  },
  setCredentials(state: any, credentials: einhundertApiAuthResponse) {
    if (credentials.access_token && credentials.refresh_token && credentials.expires_in) {
      credentials.expiresInDate = moment().add(credentials.expires_in, 'seconds').format()

      state.credentials = credentials
    }
  },
  setUserInfo(state: any, userInfo: einhundertApiUserResponse) {
    state.user.info = userInfo
  },
  setLoginUsername(state: any, username: string) {
    state.user.username = username
  },
  setLoginPassword(state: any, password: string) {
    state.user.password = password
  },
  popCustomer(state: any) {
    if (state.user.customers) {
      state.user.customers.pop()
      if (!state.user.customers.length) {
        state.user.selectedContract = undefined
      } else {
        state.user.selectedContract = state.user.customers[state.user.customers.length - 1].contracts[0]
      }
    }
  }
}
