export default {
  data: () => ({
    apiResponseDt: null,
    loading: true,
    timerId: null
  }),
  props: {
    apiRefreshSeconds: {
      type: Number,
      required: false,
      default: 60
    },
    timerInterval: {
      type: Number,
      required: false,
      default: 0
    }
  },
  beforeDestroy() {
    if (this.timerId) {
      clearInterval(this.timerId)
    }
  },
  methods: {
    refreshData(startDate = null, direction = null) {
      let now = this.$moment()
      if (!this.apiResponseDt || startDate || now.diff(this.apiResponseDt, 'seconds') >= this.apiRefreshSeconds) {
        if (typeof this.getData === 'function') {
          if (direction !== null) {
            this.loading = true;
            this.getData(startDate, direction)
          } else {
            this.getData(startDate)
          }
          this.apiResponseDt = now
        }
      }
    },
    startTimer() {
      if (this.timerInterval) {
        if (this.timerId) {
          clearInterval(this.timerId)
        }
        this.timerId = setInterval(() => { this.refreshData() }, this.timerInterval * 1000)
      }
    },
    stopTimer() {
      if (this.timerId) {
        clearInterval(this.timerId)
      }
    }
  },
  mounted() {
    if (this.timerInterval && !this.timerId) {
      this.startTimer()
    }
  }
}
