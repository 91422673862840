<template>
  <div class="container-fluid">
    <div id="bt-toolbar">
      <span class="ml-0">
        <button
          @click="loginAsUser"
          type="button"
          class="btn btn-warning"
          :disabled="!selectedRow"
        >
          Anmelden als
        </button>
      </span>
    </div>
    <BootstrapTable
      ref="customerTable"
      v-if="apiResponse"
      @on-click-row="rowClicked"
      @on-dbl-click-row="rowDblClicked"
      @on-page-change="pageChanged"
      @on-search="searched"
      :columns="tableContent"
      :data="apiResponse.results ? apiResponse.results : apiResponse"
      :options="tableOptions"
    ></BootstrapTable>
    <h3 v-else>Keine Kundendaten zum Anzeigen</h3>
  </div>
</template>

<script>
import einhundertTable from "@/mixins/einhundertTable";
import refreshTimer from "@/mixins/refreshTimer";

export default {
  name: "CustomerManager",
  mixins: [einhundertTable, refreshTimer],
  data: () => ({
    apiResponse: null,
    showForm: false,
  }),
  mounted() {
    this.$nextTick(() => {
      this.refreshData();
    });
  },
  methods: {
    getData(timestamp = null) {
      this.$http
        .get(this.$store.state.api.pathViews + "Users")
        .then((response) => {
          this.apiResponse = response.data;
        })
        .catch((e) => {
          console.error(this.name + ":", e);
        });
    },
    loginAsUser() {
      if (this.selectedRow.id !== undefined || !this.selectedRow.id) {
        this.$store.commit("account/appendUser", this.selectedRow);
        if (this.$store.state.account.user.customers) {
          this.$router.push({
            name: "overview",
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
