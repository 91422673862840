<script>
import { Bar, mixins } from 'vue-chartjs'

export default {
  extends: Bar,
  mixins: [mixins.reactiveProp],
  props: {
    options: {
      default () { return {} },
      type: Object
    },
    chartId: {
      default: 'line-chart',
      type: String
    },
    width: {
      default: 400,
      type: Number
    },
    height: {
      default: 400,
      type: Number
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object
    },
    plugins: {
      type: Array,
      default () { return [] }
    }
  },
  mounted () {
    this.$nextTick().then(() => {
      if (this.chartData) {
        this.renderChart(this.chartData, this.options)
      }
    })
  },
  watch: {
    options (newOptions, oldOptions) {
      this.$nextTick().then(() => {
        if (this.chartData) {
          this.renderChart(this.chartData, this.options)
        }
      })
    }
  }
}
</script>
