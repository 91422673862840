<template>
  <div v-if="loading" class="loading-data">
    <div class="loading-container">
      <a>Lädt</a>
      <span class="loading-dot"></span>
      <span class="loading-dot"></span>
      <span class="loading-dot"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingScreen',
  props: {
    loading: {
      type: Boolean,
      default: true,
      required: true,
    }
  }
}
</script>

<style lang="scss" soped>
@import "~@/assets/scss/custom";

@keyframes wave {
  0%, 60%, 100% {
    transform: initial;
  }

  30% {
    transform: translateY(-7px);
  }
}

.loading-data{
  background-color: $contentBoxBackground;
  backdrop-filter: $contentBoxBlur;
  color: $white;
  display: flex;
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 999;

  &>div.loading-container {
    margin: auto;
    text-shadow: 0 2px 5px rgba(0,0,0,0.25);
    user-select: none;

    > a {
      font-size: 2rem;
    }

    &>span.loading-dot {
      display: inline-block;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      margin-right: 3px;
      background-color: $white;
      animation: wave 1.3s linear infinite;

      &:nth-child(2) {
        animation-delay: -1.1s;
        margin-left: 5px;
      }

      &:nth-child(3) {
        animation-delay: -0.9s;
      }
    }
  }
}
</style>
