<template>
  <div class="c-wrapper">
    <header v-if="$store.state.account.user.info" class="c-header">
      <Navigation></Navigation>
    </header>

    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>

    <footer class="c-footer">
      <div class="container-fluid">
        <p>
          <a :href="impress_url" target="_blank">Impressum</a>
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import portal_settings from "@/assets/settings/data.js";
import theme from "@/assets/settings/theme.js";

export default {
  name: "App",
  data: () => ({
    appTitle: portal_settings.site_name,
    faq_url: portal_settings.faq_url,
    impress_url: portal_settings.impress_url
  }),
  components: {
    Navigation
  },
  created() {
    const favicon = document.querySelector("[rel=icon]");
    if (favicon !== null) {
      favicon.href = `/${theme.favicon}`;
    }

    document.title = this.$route.meta.title
      ? this.$route.meta.title + " | " + this.appTitle
      : portal_settings.site_name;
  },
  mounted() {
    this.$nextTick(() => {
      let isIE = /*@cc_on!@*/ false || !!document.documentMode;
      let isEdge = !isIE && !!window.StyleMedia;
      let msgBox = document.getElementById("browserCheckMSG");

      if (isIE == true || isEdge == true) {
        let msg = document.createElement("a");
        msg.setAttribute("href", this.faq_url);
        msg.innerText = "Klicken Sie hier um auf unsere FAQ zu gelangen.";
        msg.classList.add("bcMSG");

        msgBox.classList.add("browserCheck");
        msgBox.appendChild(msg);
      }
    });
  },
  watch: {
    $route(to) {
      document.title = to.meta.title
        ? to.meta.title + " | " + this.appTitle
        : portal_settings.site_name;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/custom";

a {
  color: $primary;
}
</style>

<style lang='scss'>
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~@/assets/scss/custom";

.table tr{
  &:hover{
    background-color: $primary !important;
    cursor: pointer;
  }

  &.bg-green{
    background-color: $primary !important;

    &:hover{
      cursor: default;
    }
  }
}

.c-wrapper {
  padding-top: 3em;
  text-align: center;
}

.c-app {
  @if $backgroundGradientSettings {
    background: $backgroundGradientColor1 !important; /* Old browsers */
    background: linear-gradient(
      $backgroundGradientSettings
    ) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$backgroundGradientColor1, endColorstr=$backgroundGradientColor2,GradientType=0 ); /* IE6-9 */
  } @else if $backgroundPic {
    background-image: url("~@/assets/img/backgrounds/"+$backgroundPic);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-origin: center;
  } @else if $backgroundColor {
    background-color: $backgroundColor !important;
  } @else {
    background-color: $white !important;
  }
  font-family: $font-family-sans-serif, "Avenir", Helvetica, Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

header {
  border: none !important;
  background-color: transparent !important;
}

footer {
  background-color: $contentBoxBackground !important;
  backdrop-filter: $contentBoxBlur;
  border: none !important;
  margin-top: 0.5em;
  text-align: center;

  a:hover{
    color: $accent !important;
    text-decoration: none !important;
  }
}

.pagination-detail {
  color: $accent;
}

.pagination {
  & > .page-item.active {
    & > .page-link {
      background-color: $primary;
      border-color: $primary;
    }
  }
  & > .page-item {
    & > .page-link {
      color: $primary;
    }
  }
}

.svg-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  color: inherit;
  vertical-align: middle;
  fill: none;
  stroke: currentColor;
}

.svg-fill {
  fill: currentColor;
  stroke: none;
}

.svg-up {
  /* default */
  transform: rotate(0deg);
}

.svg-right {
  transform: rotate(90deg);
}

.svg-down {
  transform: rotate(180deg);
}

.svg-left {
  transform: rotate(-90deg);
}

div.browserCheck {
  background-color: red;
  display: flex;
  padding: 5px;
  margin: 5px;
}
div.browserCheck::after {
  content: "Dieser Browser wird leider nicht mehr unterstützt.";
  color: white;
  position: relative;
  margin: auto;
  text-align: center;
}
div.browserCheck > a.bcMSG {
  position: absolute;
  color: white;
  border-bottom: white 1px solid;
  right: 0;
  margin-right: 5px;
  padding-right: 5px;
}
div.browserCheck > a.bcMSG:hover {
  text-decoration: none;
}

@media (max-width: 991.98px) {
  .app-body {
    margin-top: 85px !important;
  }
}

.tooltip > .tooltip-inner {
  background-color: $chartBoxBackground !important;
  box-shadow: $contentBoxShadow !important;
  backdrop-filter: $contentBoxBlur !important;
  font-family: $font-family-sans-serif;
  color: $white !important;
}

// page transition
.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-delay: 0.15s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
