<template>
  <main v-if="selectedContract" class="c-main">
    <div class="container">
      <div class="row justify-content-center">
        <template v-if="selectedContract.contract_type === '100.lessor'">
          <ChartConsumptionTotal
            v-if="selectedContract.contract_label === 'Gebäudebilanz'"
            heading="Gesamtverbrauch"
            :timerInterval="60"
          ></ChartConsumptionTotal>
          <ChartConsumptionTotal
            v-else
            heading="Verbrauch der Kundenanlage"
            :timerInterval="60"
          ></ChartConsumptionTotal>
          <ChartConsumptionSun
            v-if="selectedContract.contract_label === 'Gebäudebilanz'"
            heading="Gebäudesolarquote"
            :timerInterval="60"
          ></ChartConsumptionSun>
          <ChartConsumptionSun
            v-else
            heading="Solarquote der Kundenanlage"
            :timerInterval="60"
          ></ChartConsumptionSun>
        </template>
        <template
          v-else-if="selectedContract.contract_type === 'TenantElectricity'"
        >
          <!-- <template v-if="alreadyXmasYet()">
            <EINHUNDERTxmas></EINHUNDERTxmas>
          </template> -->
          <!-- <EINHUNDERTSurvey v-if="cID == 'einhundert_frontend'"/> -->
          <ChartConsumptionCosts :timerInterval="60"></ChartConsumptionCosts>
          <ChartConsumptionSun :timerInterval="60"></ChartConsumptionSun>
        </template>
        <template v-else>
          <ChartConsumptionCosts :timerInterval="60"></ChartConsumptionCosts>
          <ChartConsumptionLive :timerInterval="60"></ChartConsumptionLive>
        </template>
      </div>
    </div>

    <div class="background-darkgrey container-fluid my-4 p-0 chart-root">
      <div class="row">
        <ChartConsumptionYearMonth
          :timerInterval="300"
        ></ChartConsumptionYearMonth>
      </div>
    </div>

    <div class="background-darkgrey container-fluid my-4 p-0 chart-root">
      <div
        v-if="selectedContract.contract_type !== '100.lessor'"
        class="background-darkgrey row"
      >
        <ChartConsumptionDay :timerInterval="10"></ChartConsumptionDay>
      </div>
    </div>
    <div class="module module-colors">
      <div class="color" data-id="green"></div>
      <div class="color" data-id="blue"></div>
      <div class="color" data-id="brown"></div>
      <div class="color" data-id="brown-light"></div>
      <div class="color" data-id="grey"></div>
      <div class="color" data-id="grey-light"></div>
      <div class="color" data-id="white"></div>
      <div class="color" data-id="red"></div>
      <div class="color" data-id="yellow"></div>
      <div class="color" data-id="another"></div>
      <div class="color" data-id="green-dark"></div>
      <div class="color" data-id="status-ok"></div>
      <div class="color" data-id="status-middle"></div>
      <div class="color" data-id="status-warn"></div>
      <div class="color" data-id="chart-solar"></div>
      <div class="color" data-id="chart-consumption"></div>
      <div class="color" data-id="chart-average"></div>
      <div class="color" data-id="chart-load"></div>
    </div>
  </main>
  <main v-else class="c-main">
    <div class="container">
      <div class="row justify-content-center">
        <h2>
          Zugang besitzt derzeit keinen Vertrag, daher kann nichts angezeigt
          werden.
        </h2>
      </div>
    </div>
  </main>
</template>

<script>
import EINHUNDERTxmas from "@/components/EINHUNDERTxmas.vue";
import EINHUNDERTSurvey from "@/components/EINHUNDERTSurvey.vue";
import ChartConsumptionCosts from "@/components/Overview/ChartConsumptionCosts.vue";
import ChartConsumptionDay from "@/components/Overview/ChartConsumptionDay.vue";
import ChartConsumptionLive from "@/components/Overview/ChartConsumptionLive.vue";
import ChartConsumptionSun from "@/components/Overview/ChartConsumptionSun.vue";
import ChartConsumptionTotal from "@/components/Overview/ChartConsumptionTotal.vue";
import ChartConsumptionYearMonth from "@/components/Overview/ChartConsumptionYearMonth";

import contractUpdater from "@/mixins/contractUpdater";

const filter_getec_contracts = [
  "2003623",
  "2003505",
  "2003502",
  "2003501",
  "2003523",
  "2003500",
  "2003522",
  "2003521",
  "2003495",
  "2003494",
  "2003518",
  "2003519",
  "2003504",
  "2003517",
  "2003520",
  "2003493",
  "2003489",
  "2003512",
  "2003513",
  "2003511",
  "2003515",
  "2003514",
  "2003496",
  "2003624",
  "2003499",
  "2003498",
  "2003516",
];

export default {
  name: "Overview",
  components: {
    ChartConsumptionCosts,
    ChartConsumptionDay,
    ChartConsumptionLive,
    ChartConsumptionSun,
    ChartConsumptionTotal,
    ChartConsumptionYearMonth,
    ChartConsumptionYearMonth,
    EINHUNDERTxmas,
    EINHUNDERTSurvey,
  },
  data: () => ({
    cID: process.env.VUE_APP_CLIENT_ID,
  }),
  mixins: [contractUpdater],
  methods: {
    getColor(colorName) {
      return $('.module-colors .color[data-id="' + colorName + '"]').css(
        "background-color"
      );
    },
    getStartDate() {
      return this.$moment();
    },
    alreadyXmasYet() {
      var timeNow = new Date();
      if (
        timeNow.getMonth() == 11 &&
        timeNow.getDate() >= 18 &&
        timeNow.getDate() < 27
      )
        return true;
      return false;
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.$store.state.account.credentials) {
        this.$router.replace({
          name: "login",
        });
      }
      if (location.hostname.search("getec") !== -1) {
        if (
          this.$store.state.account.user.contracts.find((storeContract) =>
            filter_getec_contracts.includes(storeContract.contract_number)
          )
        ) {
          this.$router.replace({
            name: "invoices",
          });
        }
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/custom";

.c-main > .container > .row {
  justify-content: space-between;

  @include media-breakpoint-between(xs, sm) {
    flex-flow: column;
  }
}

.background-darkgrey {
  background-color: $chartBoxBackground;
}

.chart-root {
  display: flex;

  & > .row {
    width: 100%;
    margin: auto;
  }
}

.module-colors {
  .color {
    &[data-id="green"] {
      background-color: $primary;
    }

    &[data-id="blue"] {
      background-color: $blue;
    }

    &[data-id="brown"] {
      background-color: $brown;
    }

    &[data-id="brown-light"] {
      background-color: $brown-light;
    }

    &[data-id="grey"] {
      background-color: $grey;
    }

    &[data-id="grey-light"] {
      background-color: $grey-light;
    }

    &[data-id="white"] {
      background-color: $white;
    }

    &[data-id="red"] {
      background-color: $red;
    }

    &[data-id="yellow"] {
      background-color: $yellow;
    }

    &[data-id="another"] {
      background-color: $another;
    }

    &[data-id="green-dark"] {
      background-color: $green-dark;
    }

    &[data-id="status-ok"] {
      background-color: $statusColorOk;
    }

    &[data-id="status-middle"] {
      background-color: $statusColorMiddle;
    }

    &[data-id="status-warn"] {
      background-color: $statusColorWarn;
    }

    &[data-id="chart-solar"] {
      background-color: $chartColorSolar;
    }

    &[data-id="chart-consumption"] {
      background-color: $chartColorConsumption;
    }

    &[data-id="chart-average"] {
      background-color: $chartColorAverage;
    }

    &[data-id="chart-load"] {
      background-color: $chartColorLoad;
    }
  }
}
</style>
