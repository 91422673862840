export default {
  data: () => ({
    searchTerm: '',
    selectedPage: 1,
    selectedRow: null,
    selectedRowIndex: null
  }),
  props: {
    tableContent: {
      type: Array,
      required: true
    },
    tableOptions: {
      type: Object,
      required: false,
      default () {
        return {
          classes: 'table table-dark table-hover table-striped',
          clickToSelect: true,
          locale: 'de-DE',
          multipleSelectRow: false,
          pageSize: 10,
          pagination: true,
          search: true,
          showSearchClearButton: true,
          singleSelect: true,
          toolbar: '#bt-toolbar'
        }
      }
    }
  },
  methods : {
    pageChanged(number, size) {
      this.selectedPage = number
      if (this.selectedRowIndex !== null) {
        let $element = $(`tr[data-index="${this.selectedRowIndex}"]`)
        if ($element && !$element.hasClass('bg-green')) {
          $element.addClass('bg-green')
        }
      }
    },
    rowClicked(row, $element, field) {
      if (!Object.is(row, this.selectedRow)) {
        $element.addClass('bg-green').siblings().removeClass('bg-green')
        this.selectedRowIndex = $element.attr('data-index')
        this.selectedRow = row
      } else {
        $element.removeClass('bg-green')
        this.selectedRowIndex = null
        this.selectedRow = null
      }
      this.formResultObject = null
    },
    rowDblClicked(row, $element, field) {
      this.formResultObject = null
      this.selectedRowIndex = $element.attr('data-index')
      this.selectedRow = row
      this.showForm = true
    },
    searched(searchTerm) {
      this.searchTerm = searchTerm
      if (!searchTerm) {
        let $element = $(`tr[data-index="${this.selectedRowIndex}"]`)
        if ($element && $element.hasClass('bg-green')) {
          $element.removeClass('bg-green')
        }
        this.selectedRowIndex = null
      }
    }
  }
}
