import { Module } from 'vuex'

import { actions } from './actions'
import { mutations } from './mutations'
import { AccountState } from './types'
import { RootState } from '../types'

export const accountInitialState: AccountState = {
  credentials: undefined,
  loginError: false,
  user: {
    contracts: undefined,
    info: undefined,
    password: '',
    selectedContract: undefined,
    username: '',
    customers: []
  }
}

export const account: Module<AccountState, RootState> = {
  namespaced: true,
  state: accountInitialState,
  actions,
  mutations
}
