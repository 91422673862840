<template>
  <div v-if="isOpen" class="errorbox-data">
    <div class="errorbox-container">
      <span>{{msg}}</span>
      <span>Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorWrapper',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true
    },
    msg: {
      type: String,
      default: 'Ein unbekannter Fehler ist aufgetreten.',
      required: true
    }
  }
}
</script>


<style lang="scss" soped>
@import "~@/assets/scss/custom";

@keyframes wave {
  0%, 60%, 100% {
    transform: initial;
  }

  30% {
    transform: translateY(-7px);
  }
}

.errorbox-data{
  background-color: $contentBoxBackground;
  backdrop-filter: $contentBoxBlur;
  color: $white;
  display: flex;
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 999;

  &>div.errorbox-container {
    display: flex;
    flex-flow: column wrap;
    margin: auto;
    padding: 14px;
    text-shadow: 0 2px 5px rgba(0,0,0,0.25);
    user-select: none;

    > span {
      font-size: 1.2rem;
    }

  }
}
</style>
