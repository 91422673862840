<template>
  <main class="c-main">
    <div class="container">
      <div class="questionRoot">
        <div v-for="(question, category) in questions" :key="category">
          <br/>
          <h3 class="category-heading">{{ category }}</h3>
          <br/>
          <div
            v-for="(q, i) in question"
            :key="'question_' + i"
          >
            <Accordion :title="q[0]" style="padding: 5px 0;">
              <br/>
              <a v-html="q[1]"/>
            </Accordion>
          </div>
        </div>
        <div id="bottomFaq">
          <p>
            Noch Fragen?
            <a :href="faq_url" target="_blank">Hier gibt's Antworten!</a>
          </p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Accordion from '@/components/Accordion'
import portal_setting from "@/assets/settings/data.js";

export default {
  name: "Faq",
  components: {Accordion},
  data() {
    return {
      questions: null,
      faq_url: portal_setting.faq_url,
      faqs: async () => await import(`@/assets/faq/${portal_setting.faq_file}`),
    }
    
  },
  async created() {
    const {default: faqs} = await this.faqs()
    this.questions = faqs
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.$store.state.account.credentials) {
        this.$router.replace({
          name: "login",
        });
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/custom";

.container {
  text-align: initial !important;
  background-color: $faqBackground;
  backdrop-filter: $contentBoxBlur;
  padding: 15px;
  margin: auto;
  text-align: left;

  & > .questionRoot {
    height: 100%;
    padding: 15px;
    color: $accent;

    #bottomFaq {
      float: right;
      color: white;

      a {
        color: $primary !important;
      }
    }
  }
}
.category-heading {
  color: $faqHeading;
}
</style>
