<template>
  <div class="accordion">
    <div class="accordion_label" @click="expandList">
      <h5>
        <font-awesome-icon class="icon" :icon="expanded ? 'minus' : 'plus'"></font-awesome-icon>
        {{ title }}
      </h5>
    </div>
    <ul class="accordion_list" :class="{'is-expanded': expanded}">
      <slot></slot>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Accordion',
  props: ['title'],
  data: () => ({
    expanded: false,
  }),
  methods: {
    expandList(){
      this.expanded = !this.expanded
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/custom";

.accordion{
  padding: 10px ​0;

  &_label{
    display: flex;
    flex-flow: row wrap;
    color: $faqQuestion;

    &:hover {
      color: $faqAnswer;
      cursor: pointer;
    }

    &>svg{
      margin: auto 0;
    }

    &>h5{
      margin: auto 10px;
    }
  }
}
.accordion_list{
  color: $faqAnswer;
  margin-left: 15px;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  &.is-expanded {
    max-height: 1000px;
    transition: max-height 1s ease-in-out;
  }
}
</style>
