export default {
  computed: {
    selectedContract () {
      return this.$store.state.account.user.selectedContract
    }
  },
  methods: {
    refreshData () {}
  },
  watch: {
    selectedContract () {
      this.apiResponseDt = null
      this.refreshData()
    }
  }
}
