<template>
  <main class="c-main">
    <div class="container">
      <h2>Kundenverwaltung</h2>
      <CustomerManager :tableContent="customerTableContent"></CustomerManager>
    </div>
  </main>
</template>

<script>
import { Switch } from "@coreui/vue";
import CustomerManager from "@/components/CustomerManager";

export default {
  name: "EinhundertAdmin",
  data: () => ({
    menuOpen: true,
    customerTableContent: [
      {
        title: "Vorname",
        field: "first_name",
      },
      {
        title: "Nachname",
        field: "last_name",
      },
      {
        title: "E-Mail",
        field: "email",
      },
    ],
  }),
  components: {
    CustomerManager,
  },
  created() {
    if (!this.$store.state.account.credentials) {
      this.$router.push({
        name: "login",
      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.$store.state.account.credentials) {
        this.$router.push({
          name: "login",
        });
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/custom";

.container > h2 {
  text-align: left;
  color: white;
}

main {
  flex: 1;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  position: relative;
}

.menu {
  &-side {
    left: 0px;
    position: fixed;
    height: 600px;
    background-color: $contentBoxBackground;
    backdrop-filter: $contentBoxBlur;
    border-radius: 0 4px 4px 0;
    transition: 0.2s all ease-in-out;
    z-index: 20;

    & > &-content > ul {
      list-style: none;
      padding: 0;
      margin: 0;
      transition: 0.2s all ease-in-out;

      & > li {
        display: grid;

        & > a {
          color: $primary;
          padding: 10px 25px;

          &:hover {
            color: $accent;
            background-color: $primary;
            text-decoration: none;
            border-radius: 0 4px 4px 0;
          }

          &.router-link-active {
            color: $accent;
          }

          & > svg {
            margin: 5px;
          }
        }
      }
    }

    & > &-opener {
      display: flex;
      position: absolute;
      right: -13px;
      top: 0;
      height: 100%;

      & > div {
        background-color: $primary;
        border: none;
        margin: auto;
        display: flex;
        padding: 5px 8px;
        border-radius: 50px;
        transition: 0.2s all ease-in-out;

        &:hover {
          cursor: pointer;
          background-color: $accent;

          & > svg {
            color: $primary;
          }
        }

        & > svg {
          margin: auto;
          color: $accent;
          transform: rotate(180deg);
          transition: 0.2s all ease-in-out;
        }
      }
    }
  }

  &-table {
    flex: 1;
    transition: 0.2s all ease-in-out;
    padding-left: 213px;
  }
}
</style>
