<template>
  <div
    class="module module-chart chart-half chart-costs"
    :data-status="consumptionStatus"
  >
    <LoadingScreen :loading="loading" />
    <div class="chart-inner">
      <h2>{{ heading }}</h2>

      <div class="chart-canvas">
        <div class="doughnut-center">
          <div class="days">
            1.
            <template v-if="daysElapsed > 1">- {{ daysElapsed }}.</template>
          </div>
          <div class="month">{{ monthText }}</div>
          <div class="now">{{ currentConsumption | formatConsumption }}</div>
        </div>

        <DoughnutChart
          chartId="chart-consumption-costs"
          cssClasses="chart-canvas"
          :chartData="chartData"
          :options="chartOptions"
          :width="1"
          :height="1"
        ></DoughnutChart>
      </div>
    </div>
  </div>
</template>

<script>
import DoughnutChart from "@/components/DoughnutChart";
import LoadingScreen from "@/components/Helper/LoadingScreen";

import contractUpdater from "@/mixins/contractUpdater";
import refreshTimer from "@/mixins/refreshTimer";
import theme from "@/assets/settings/theme.js";

export default {
  name: "ChartConsumptionTotal",
  components: {
    DoughnutChart,
    LoadingScreen
  },
  mixins: [contractUpdater, refreshTimer],
  data: () => ({
    apiResponse: null,
    chartOptions: null
  }),
  props: {
    heading: {
      type: String,
      required: false,
      default: "Mein Verbrauch"
    }
  },
  computed: {
    chartData() {
      if (!this.apiResponse) {
        return {};
      }

      var data = [];
      var backgroundColors = [];
      var usageRest = this.apiResponse.usage_limit;

      var colorState = this.getColorFromStatus(this.apiResponse.cost_status);
      var colorGrey = this.$parent.getColor("grey-light");
      var colorGreenDark = this.$parent.getColor("green-dark");

      $.each(this.apiResponse.values, function() {
        var value = this;
        data.push(value.usage);
        backgroundColors.push(colorState);

        usageRest -= value.usage;
      });

      if (usageRest > 0) {
        data.push(usageRest);
        backgroundColors.push(colorGrey);
      }

      return {
        datasets: [
          {
            data: data,
            backgroundColor: backgroundColors,
            borderColor: theme.doughnut_border_color
          }
        ]
      };
    },
    currentConsumption() {
      if (!this.apiResponse) return "Keine Daten empfangen.";

      return this.apiResponse.usage;
    },
    consumptionStatus() {
      if (!this.apiResponse) return "low";

      return this.apiResponse.cost_status;
    },
    daysElapsed() {
      if (!this.apiResponse) return 1;

      return this.apiResponse.days_elapsed;
    },
    monthText() {
      if (!this.apiResponse) return "Keine Daten empfangen.";

      return this.$moment(this.apiResponse.month, "M").format("MMMM");
    }
  },
  created() {
    this.chartOptions = {
      cutoutPercentage: 75,
      responsive: true,
      legend: false,
      animation: {
        animateScale: true,
        animateRotate: true,
        onProgress: () => {
          this.setChartHalfHeight();
        }
      },
      tooltips: {
        enabled: false
      },
      hover: {
        mode: null
      }
    };
  },
  filters: {
    formatConsumption(value) {
      if (!value) return "Keine Daten empfangen.";

      return (
        value.toLocaleString("de", {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0
        }) + " kWh"
      );
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshData();
    });
  },
  methods: {
    getColorFromStatus(status) {
      if (status === "high") {
        return this.$parent.getColor("red");
      } else if (status === "middle") {
        return this.$parent.getColor("yellow");
      }

      return this.$parent.getColor("green");
    },
    getData(timestamp = null) {
      if (this.selectedContract) {
        var path =
          this.$store.state.api.pathViewsV3 +
          "Contracts/" +
          this.selectedContract.hid +
          "/Measurements/Overview";
        const customers = this.$store.state.account.user.customers;
        if (customers.length) {
          path +=
            "?customerId=" + customers[customers.length - 1].customerInfo.id;
        }

        this.$http
          .get(path)
          .then(response => {
            this.loading = false;
            this.apiResponse = response.data;
          })
          .catch(e => {
            console.error(this.name + ":", e);
          });
      }
    },
    setChartHalfHeight() {
      var $chartHalf = $(".chart-half .chart-inner");
      $chartHalf.css("min-height", "0");

      var chartMinHeight = 0;

      $chartHalf.each(function() {
        var chartHeight = $(this).outerHeight();

        if (chartHeight > chartMinHeight) {
          chartMinHeight = chartHeight;
        }
      });

      $chartHalf.css("min-height", chartMinHeight);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@coreui/coreui/scss/functions";
@import "~@coreui/coreui/scss/variables";
@import "~@coreui/coreui/scss/mixins/_breakpoints";
@import "~@/assets/scss/custom";
@import "~@/assets/scss/components/consumption_doughnut";
</style>
