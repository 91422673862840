import { Module } from 'vuex'

import { ApiState } from './types'
import { RootState } from '../types'

export const apiInitialState: ApiState = {
  path: process.env.VUE_APP_API_URL,
  pathViews: 'api/v2/',
  pathViewsV3: 'api/v3/',
  pathEnd: '',
  clientId: process.env.VUE_APP_CLIENT_ID,
  clientSecret: process.env.VUE_APP_CLIENT_SECRET
}

export const api: Module<ApiState, RootState> = {
  state: apiInitialState,
  namespaced: true
}
