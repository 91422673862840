<template>
  <div class="module module-chart chart-half chart-live">
    <LoadingScreen :loading="loading" />
    <ErrorWrapper :isOpen="errorboxOpen" :msg="errorboxMsg"/>
    <div class="chart-inner">
      <h2>Mein Live-Verbrauch</h2>

      <div class="chart-canvas">
        <div class="doughnut-center text">
          <div class="days">{{ periodText }}</div>
          <div class="hour">letzte Stunde</div>
          <div class="value-current">{{ powerCurrentHour|formatFloat('kWh', 2) }}</div>
        </div>

        <DoughnutChart
          chartId="chart-consumption-live"
          cssClasses="chart-canvas"
          :chartData="chartData"
          :options="chartOptions"
          :width="1"
          :height="1"
        ></DoughnutChart>
      </div>

      <div v-if="powerLastHourRatio || powerLastDayRatio" class="box-compares">
        <template v-if="powerLastHourRatio">
          <div
            v-if="powerLastHourRatio > 0"
            class="box-compare box-compare--first box-compare--higher"
          >
            <span class="box-compare__value">
              <span class="box-compare__icon">
                <font-awesome-icon class="icon" icon="arrow-circle-up"></font-awesome-icon>
              </span>
              <span class="box-compare__value-inner">+{{ powerLastHourRatio|formatFloat('%') }}</span>
            </span>
            <span class="box-compare__text">zur Stunde davor</span>
          </div>
          <div v-else class="box-compare box-compare--first box-compare--lower">
            <span class="box-compare__value">
              <span class="box-compare__icon">
                <font-awesome-icon class="icon" icon="arrow-circle-down"></font-awesome-icon>
              </span>
              <span class="box-compare__value-inner">{{ powerLastHourRatio|formatFloat('%') }}</span>
            </span>
            <span class="box-compare__text">zur Stunde davor</span>
          </div>
        </template>

        <template v-if="powerLastDayRatio">
          <div
            v-if="powerLastDayRatio > 0"
            class="box-compare box-compare--second box-compare--higher"
          >
            <span class="box-compare__value">
              <span class="box-compare__icon">
                <font-awesome-icon class="icon" icon="arrow-circle-up"></font-awesome-icon>
              </span>
              <span class="box-compare__value-inner">+{{ powerLastDayRatio|formatFloat('%') }}</span>
            </span>
            <span class="box-compare__text">zur gleichen Stunde gestern</span>
          </div>
          <div v-else class="box-compare box-compare--second box-compare--lower">
            <span class="box-compare__value">
              <span class="box-compare__icon">
                <font-awesome-icon class="icon" icon="arrow-circle-down"></font-awesome-icon>
              </span>
              <span class="box-compare__value-inner">{{ powerLastDayRatio|formatFloat('%') }}</span>
            </span>
            <span class="box-compare__text">zur gleichen Stunde gestern</span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import DoughnutChart from "@/components/DoughnutChart";
import ErrorWrapper from '@/components/Helper/ErrorWrapper'
import LoadingScreen from '@/components/Helper/LoadingScreen'

import contractUpdater from "@/mixins/contractUpdater";
import refreshTimer from "@/mixins/refreshTimer";

export default {
  name: "ChartConsumptionLive",
  components: {
    DoughnutChart,
    ErrorWrapper,
    LoadingScreen,
  },
  mixins: [contractUpdater, refreshTimer],
  data: () => ({
    apiResponse: null,
    chartOptions: null,
    loading: true,
    errorboxOpen: false,
    errorboxMsg: '',
  }),
  computed: {
    chartData() {
      if (!this.apiResponse) {
        return {};
      }

      return {
        datasets: [
          {
            backgroundColor: this.$parent.getColor("white"),
            borderColor: this.$parent.getColor("white"),
            data: [1]
          }
        ]
      };
    },
    periodText() {
      if (!this.apiResponse) {
        return "Keine Daten empfangen.";
      }

      var startDatetime = this.$moment(
        this.apiResponse.power_last_hour.timestamp
      );
      var endDatetime = this.$moment(
        this.apiResponse.power_current_hour.timestamp
      );

      if (!startDatetime.isValid() || !endDatetime.isValid()) {
        return "Keine Daten empfangen.";
      }

      return startDatetime.format("H:mm") + " - " + endDatetime.format("H:mm");
    },
    powerCurrentHour() {
      if (!this.apiResponse) {
        return null;
      }

      return this.apiResponse.power_current_hour.value;
    },
    powerLastDayRatio() {
      if (
        !this.apiResponse ||
        !this.apiResponse.power_current_hour_last_day.current_hour_ratio
      ) {
        return null;
      }

      return (
        (this.apiResponse.power_current_hour_last_day.current_hour_ratio - 1) *
        100
      );
    },
    powerLastHourRatio() {
      if (
        !this.apiResponse ||
        !this.apiResponse.power_last_hour.current_hour_ratio
      ) {
        return null;
      }

      return (this.apiResponse.power_last_hour.current_hour_ratio - 1) * 100;
    }
  },
  created() {
    this.chartOptions = {
      cutoutPercentage: 96,
      responsive: true,
      legend: false,
      animation: {
        animateScale: true,
        animateRotate: true,
        onProgress: () => {
          this.setChartHalfHeight();
        }
      },
      tooltips: {
        enabled: false
      },
      hover: {
        mode: null
      }
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshData();
    });
  },
  methods: {
    getData(timestamp = null) {
      if (this.selectedContract) {
        var path =
          this.$store.state.api.pathViewsV3 +
          "Contracts/" +
          this.selectedContract.hid +
          "/Measurements/Consumption/Live";

        if (this.$store.state.account.user.customers.length) {
          path +=
            "?customerId=" +
            this.$store.state.account.user.customers[
              this.$store.state.account.user.customers.length - 1
            ].customerInfo.id;
        }

        this.$http
          .get(path, {timeout: 30000})
          .then(response => {
            this.loading = false;
            this.apiResponse = response.data;
          })
          .catch(e => {
            this.errorboxMsg = e.message.startsWith('timeout')
              ? 'Die Datenabfrage dauert derzeit ungewöhnlich lang.'
              : undefined
            this.loading = false
            this.errorboxOpen = true
            console.error("%o", e);
          });
      }
    },
    setChartHalfHeight() {
      var $chartHalf = $(".chart-half .chart-inner");
      $chartHalf.css("min-height", "0");

      var chartMinHeight = 0;

      $chartHalf.each(function() {
        var chartHeight = $(this).outerHeight();

        if (chartHeight > chartMinHeight) {
          chartMinHeight = chartHeight;
        }
      });

      $chartHalf.css("min-height", chartMinHeight);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@coreui/coreui/scss/functions";
@import "~@coreui/coreui/scss/variables";
@import "~@coreui/coreui/scss/mixins/_breakpoints";
@import "~@/assets/scss/custom";
@import "~@/assets/scss/components/consumption_doughnut";
</style>
