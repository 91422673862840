<template>
  <main class="c-main">
    <div class="container">
      <div class="module module-logo">
        <div class="inner"></div>
      </div>

      <div class="module module-form">
        <h1>{{loginMSG}}</h1>

        <div v-if="loginError" class="alert alert-danger" role="alert">
          {{ loginError }}
        </div>

        <form @submit.prevent="loginAccount" class="login-container">
          <div class="form-group">
            <input
              type="text"
              v-model="username"
              placeholder="E-Mail"
              class="form-control"
            />
          </div>

          <div style="position: relative" class="form-group">
            <input
              :type="passwordFieldType"
              v-model="password"
              placeholder="Passwort"
              class="form-control"
              autocomplete="on"
            />
            <span @click="togglePW" class="passwordToggle">
              <img :src="require(`@/assets/icons/${togglePWIcon}.svg`)" />
            </span>
          </div>

          <p>
            <router-link :to="{ name: 'password_forget' }"
              >Passwort vergessen?</router-link
            >
          </p>

          <div class="form-group">
            <input
              type="submit"
              value="Anmelden"
              class="btn btn-primary btn-block"
              :disabled="loading"
            />
          </div>
           <CSpinner color="accent" v-if="loading"/>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
import json from "@/assets/settings/data.js";
import { CSpinner } from '@coreui/vue';

export default {
  name: "Login",
  data: () => ({
    username: "",
    password: "",
    loginError: "",
    passwordFieldType: "password",
    togglePWIcon: "eye_crossed-24px",
    loginMSG: json.login_message ? json.login_message : '',
    loading: false,
  }),
  components: {
    CSpinner,
  },
  created() {
    if (this.$store.state.account.user.username) {
      this.username = this.$store.state.account.user.username;
    }

    if (this.$store.state.account.user.password) {
      this.password = this.$store.state.account.user.password;
    }
  },
  methods: {
    loginAccount() {
      this.loading = true;
      this.$http
        .post(
          "oauth/token",
          `grant_type=password&client_id=${encodeURIComponent(
            this.$store.state.api.clientId
          )}&username=${encodeURIComponent(
            this.username.toLowerCase()
          )}&password=${encodeURIComponent(this.password)}`
        )
        .then((response) => {
          this.$store.commit(
            "account/setLoginUsername",
            this.username.toLowerCase()
          );
          this.$store.commit("account/setLoginPassword", this.password);
          this.$store.commit("account/setCredentials", response.data);
          Promise.all([this.getContracts(), this.getUserInfo()]).then(
            (results) => {
              this.loading = false;
              this.$store.commit("account/setContracts", results[0]);
              this.$store.commit("account/setUserInfo", results[1]);
              this.determineView();
            }
          ).catch(() => {
            this.loading = false;
          });
        })
        .catch((error) => {
          this.loading = false;
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (
              error.response.status === 400 ||
              error.response.status === 401
            ) {
              this.loginError =
                "Die von Ihnen eingegebenen Benutzerdaten sind nicht korrekt. Bitte versuchen Sie es erneut.";
              return;
            }
          } else if (error.request) {
            // The request was made but no response was received
            console.error(error.request);
          } else {
            console.error("Error:", error.message);
          }
          this.loginError =
            "Unbekannter Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.";
        });
    },
    async getContracts() {
      const res = await this.$http.get(this.$store.state.api.pathViewsV3 + "Contracts")
      return new Promise((resolve, reject) => {
        this.$http
          .get(this.$store.state.api.pathViewsV3 + "Contracts?offset=0&limit="+res.data.count)
          .then((response) => {
            resolve(response.data.results);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    getUserInfo() {
      return new Promise((resolve, reject) => {
        this.$http
          .get(this.$store.state.api.pathViews + "LoginCount")
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    determineView() {
      if (this.$store.state.account.user.info.group === "admin") {
        this.$router.push({
          name: "admin",
        });
      } else {
        this.$router.push({
          name: "overview",
        });
      }
    },
    togglePW() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.togglePWIcon =
        this.passwordFieldType === "password" ? "eye_crossed-24px" : "eye-24px";
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$store.state.account.credentials) {
        if (this.$store.state.account.user.info) {
          this.determineView();
        } else if (
          this.$moment(this.$store.state.account.credentials.expiresInDate) >
          this.$moment()
        ) {
          this.getUserInfo()
            .then((response) => {
              this.$store.commit("account/setUserInfo", response);
              this.determineView();
            })
            .catch((error) => {
              console.error("Login: " + error);
            });
        }
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/custom";

.container {
  max-width: 400px;
  margin: 0 auto;
}

.module-form {
  background-color: $loginBoxBackground;
  backdrop-filter: $contentBoxBlur;
  padding: 120px 0px 5px;

  text-align: center;
  color: $accent;

  h1 {
    text-transform: uppercase;
    font-family: $font-bold;
    font-size: 32px;

    padding: 10px 0;
    color: $loginPrimary;
  }

  .alert {
    &.alert-danger,
    &.alert-info {
      border: none;
      color: $loginPrimary;

      a {
        color: $loginPrimary;
        text-decoration: underline;
      }
    }

    &.alert-danger {
      background: $red;
    }

    &.alert-info {
      background: $blue;
    }
  }

  input {
    border-color: $grey-light;
  }

  a {
    &:hover {
      color: $loginPrimary;
    }
    color: $loginAccent;
  }

  .btn-primary {
    text-transform: uppercase;
    background: $loginButton;
    color: $loginPrimary;
    border: none;

    padding-top: 8px;
    padding-bottom: 8px;

    cursor: pointer;
    border-radius: 0;
  }
}

.module-logo {
  $size: 100px;

  width: $size;
  height: $size;

  margin: 0 auto;

  position: relative;
  top: 120px;
  z-index: 99;

  .inner {
    @if $logo {
      background-image: url("~@/assets/img/logos/" + $logo);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }


    width: 100%;
    height: 100%;
  }
}

.login-container {
  padding: 20px;
}

.passwordToggle {
  position: absolute;
  right: 0;
  top: 0;
  padding: 5px;
}
</style>
