<template>
  <div class="module module-chart chart-day">
    <LoadingScreen :loading="loading" />
    <ErrorWrapper :isOpen="errorboxOpen" :msg="errorboxMsg" />
    <div class="chart-inner">
      <h2>
        Leistungskurve
        <br />
        <span v-if="isToday">heute</span>
        <span v-else>{{ dayText }}</span>
      </h2>

      <p
        v-if="!(chartData && chartData.datasets && chartData.datasets[0] && chartData.datasets[0].data && chartData.datasets[0].data.length > 0)"
      >Keine Messwerte vorhanden.</p>

      <div class="label-box">
        <a v-if="!isToday" href="javascript:void(0)" class="label" @click="goToday">Heute</a>
      </div>

      <div class="chart-navigation-outer">
        <div class="chart-navigation nav-back">
          <div data-action="back" v-if="backEnabled" @click="refreshData(dateBack, 'back')">
            <font-awesome-icon class="icon" icon="chevron-left"></font-awesome-icon>
          </div>
        </div>

        <div
          v-if="chartData && chartData.datasets && chartData.datasets.length"
          class="chart-scroller-outer"
        >
          <div class="chart-scroller">
            <LineChart
              chartId="chart-consumption-day"
              cssClasses="chart-canvas"
              :chartData="chartData"
              :options="chartOptions"
              :plugins="chartPlugins"
            ></LineChart>
          </div>
        </div>

        <div class="chart-navigation nav-forward">
          <div
            v-if="forwardEnabled"
            data-action="forward"
            @click="refreshData(dateForward, 'forward')"
          >
            <font-awesome-icon class="icon" icon="chevron-right"></font-awesome-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorWrapper from '@/components/Helper/ErrorWrapper'
import LineChart from "@/components/LineChart";
import LoadingScreen from '@/components/Helper/LoadingScreen'

import contractUpdater from "@/mixins/contractUpdater";
import refreshTimer from "@/mixins/refreshTimer";
import moment from "moment";
import theme from "@/assets/settings/theme.js";

export default {
  name: "ChartConsumptionDay",
  components: {
    ErrorWrapper,
    LineChart,
    LoadingScreen,
  },
  mixins: [contractUpdater, refreshTimer],
  data: () => ({
    apiResponse: null,
    chartOptions: null,
    chartPlugins: [],
    dateCurrent: moment().startOf("day"),
    timerId: null,
    loading: true,
    errorboxOpen: false,
    errorboxMsg: '',
  }),
  props: {
    startDate: {
      type: moment,
      required: false,
      default: null
    }
  },
  computed: {
    backEnabled() {
      if (!this.apiResponse) return false;
      return this.dateCurrent.isAfter(
        moment()
          .startOf("day")
          .subtract(7, "days"),
        "day"
      );
    },
    chartData() {
      if (this.dateCurrent === null) return undefined;

      var labels = [];
      var data = [];

      if (
        this.apiResponse &&
        this.apiResponse.length > 1
      ) {
        this.apiResponse.forEach(value => {
          labels.push(this.$moment(value.timestamp));
          data.push(value.power);
        });
      }

      this.setChartOptions();
      return {
        labels: labels,
        datasets: [
          {
            backgroundColor: "transparent",
            borderColor: this.$parent.getColor("chart-load"),
            data: data
          }
        ]
      };
    },
    dateBack() {
      if (!this.dateCurrent) return undefined;

      return this.dateCurrent.clone().subtract(1, "days");
    },
    dateForward() {
      if (!this.dateCurrent) return undefined;

      return this.dateCurrent.clone().add(1, "days");
    },
    dayText() {
      if (!this.dateCurrent) return "Keine Daten empfangen.";

      return this.dateCurrent.format("DD.MM.YY");
    },
    forwardEnabled() {
      return !this.isToday;
    },
    isToday() {
      if (!this.dateCurrent) return false;

      return this.dateCurrent.isSame(this.$moment(), "day");
    },
  },
  mounted() {
    if (this.timerInterval) {
      if (this.timerId) {
        clearInterval(this.timerId);
      }
      this.timerId = setInterval(() => {
        this.refreshData();
      }, this.timerInterval * 1000);
    }

    this.$nextTick(() => {
      this.refreshData(this.startDate);
    });
  },
  methods: {
    getData(timestamp = null, direction = null) {
      if (this.selectedContract) {
        var path =
          this.$store.state.api.pathViewsV3 +
          "Contracts/" +
          this.selectedContract.hid +
          "/Measurements/Load/Day/";

        if (timestamp) {
          path += timestamp.format("YYYY/MM/DD");
        } else {
          path += "Current";
        }

        if (this.$store.state.account.user.customers.length) {
          path +=
            "?customerId=" +
            this.$store.state.account.user.customers[
              this.$store.state.account.user.customers.length - 1
            ].customerInfo.id;
        }

        this.$http
          .get(path, {timeout: 30000})
          .then(response => {
            this.loading = false;
            this.apiResponse = response.data;
            if (
              this.apiResponse.length === 1 &&
              this.apiResponse[0].power === 0
            ) {
              if (direction === "back")
                this.dateCurrent = this.dateCurrent.clone().subtract(1, "days");
              else if (direction === "forward")
                this.dateCurrent = this.dateCurrent.clone().add(1, "days");
            } else {
              this.dateCurrent = this.$moment(
                this.apiResponse[Math.trunc(this.apiResponse.length / 2)]
                  .timestamp
              );
            }
          })
          .catch(e => {
            this.errorboxMsg = e.message.startsWith('timeout')
              ? 'Die Datenabfrage dauert derzeit ungewöhnlich lang.'
              : undefined
            this.loading = false
            this.errorboxOpen = true
            console.error("%o", e);
          });
      }
    },
    setChartOptions() {
      // var colorWhite = this.$parent.getColor("white");  instanciating before DOM element exists => 'undefined' => no color in chart. Commenting to use it later in refactor.

      this.chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        legend: false,
        elements: {
          line: {
            tension: 0
          },
          point: {
            radius: 2
          }
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
                color: theme.color_chart_grid,
                drawBorder: false,
                zeroLineColor: "white"
              },
              ticks: {
                beginAtZero: true,
                fontColor: theme.color_chart_grid,
                autoSkip: false,
                maxRotation: 0,
                minRotation: 0,

                callback: function(momentValue) {
                  var hour = parseInt(momentValue.format("H"));
                  var minutes = parseInt(momentValue.format("mm"));

                  if (hour !== 0 && hour % 3 !== 0) {
                    return null;
                  }

                  if (minutes !== 0) {
                    return null;
                  }

                  return momentValue.format("H:mm");
                }
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                zeroLineColor: "white",
                color: theme.color_chart_grid,
                drawBorder: false
              },
              ticks: {
                beginAtZero: true,
                fontColor: theme.color_chart_grid,
                callback(value) {
                  return (
                    value.toLocaleString("de", {
                      maximumFractionDigits: 0
                    }) + " W"
                  );
                }
              }
            }
          ]
        },
        tooltips: {
          enabled: true,
          callbacks: {
            title: (tooltipItems, data) => {
              return tooltipItems[0].xLabel.format("llll") + " Uhr";
            },
            label: (tooltipItems, data) => {
              return tooltipItems.value + " W";
            }
          }
        }
      };
    },
    goToday() {
      this.dateCurrent = moment().startOf("day");
      this.loading = true;

      this.refreshData(this.dateCurrent);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@coreui/coreui/scss/functions";
@import "~@coreui/coreui/scss/variables";
@import "~@coreui/coreui/scss/mixins/_breakpoints";
@import "~@/assets/scss/custom";
@import "~@/assets/scss/components/consumption";

h2 {
  font-family: $font-bold;
}
</style>
